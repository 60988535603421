//公司相关接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import {Loading, MessageBox} from "element-ui";

//获取某个公司下的门店列表shopList
function getShop(id) {
    return axios.post(publicFn.getUrl('shopList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            "SearchRange":"company",//这里加这句
            "ShopId": store.state.users.user.UserShopId,
            CompanyId: id,
            "IndexMin": 0,
            "IndexMax": 0,
            "PageIndex": 0,
            "PageSize": 0,
        }
    })
}


//获取门店当天患者列表GetCustomerListByChoose
function getDayCs() {
  return axios.post(publicFn.getUrl('GetCustomerListByChoose'), {
      "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
          EditTimeStart: publicFn.getTime(0),
          EditTimeEnd: publicFn.getTime(1),
          ShopId: store.state.bases.shop.ShopId,
          "SearchRange":"shop"//增加这句
      }
  })
}
//获取门店所有患者列表GetCustomerListByChoose
function getAllCs(max=100, min=1) {
    return axios.post(publicFn.getUrl('GetCustomerListByChoose'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            IndexMax: max,
            IndexMin: min,
            ShopId: store.state.bases.shop.ShopId,
            "SearchRange":"shop"//增加这句
        }
    })
}
// 获取患者咨询列表getCustomerListDetailInfo
function getCsConsulting(id) {
    // console.log()
    return axios.post(publicFn.getUrl('getCustomerListDetailInfo'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            ChooseCompanyId: store.state.users.user.Company.CompanyId,
            IndexMax: 100,
            IndexMin: 1,
            CustomerId: id,
            ChooseShopId: store.state.bases.shop.ShopChannel == 'hospital' ? store.state.bases.shop.ShopId : '',
            "Choose":{
                "SearchRange": store.state.bases.shop.ShopChannel == 'hospital' ? 'shop' : "company"//增加这句
            },
        },
        ReturnInfo: {ChooseFull: {}}
    })
}
// 搜索患者GetCustomerListByChoose
function searchCs(name, time) {
     let telzz = /^1[3-9]\d{9}$/,
         n = '',
         tel = ''
    telzz.test(name) ? tel=name : n = name
    return axios.post(publicFn.getUrl('GetCustomerListByChoose'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            IndexMax: 100,
            IndexMin: 1,
            ShopId: store.state.bases.shop.ShopId,
            EditTimeStart: time[0] ? time[0] + ' 0:00:00' : '',
            EditTimeEnd: time[1] ? time[1] + ' 23:59:59' : '',
            CustomerRegCompanyId: store.state.users.user.Company.CompanyId,
            "SearchRange":"shop",
            Customer: {
                CustomerName: n,
                CustomerTel: tel
            }
        }
    })
}


//获取就诊列表的接口getCustomerAppointmentList
function getTriageList(deip = '', exid) {
   return axios.post(publicFn.getUrl('getCustomerAppointmentList'), {
       "token":{"sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"},
       "SearchCondition": {
           "IndexMax":100,
           "OppointmentTimeStart": publicFn.getTime(0),
           "bOrderToCall":1,
           "IndexMin":1,
           "OppointmentShopId": store.state.bases.shop.ShopId,
           "OppointmentTimeEnd": publicFn.getTime(28),
           OppointmentState: 'expected',
           OppointmentDeptId: deip,
           OppointmentExpertId: exid
       },
       "ReturnInfo":{"CustomerFull":{"ChooseFull":null}}
   })
}
//获取科室的接口GetList_Dept
function getDept() {
    return axios.post(publicFn.getUrl('GetList_Dept'), {
        "token":{"sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"},
        SearchCondition: {
            "IndexMax":100,
            "IndexMin":1,
            "ShopId": store.state.bases.shop.ShopId,
            bLock: 0,
        }
    })
}
//叫号接口UpdateOC
function callHao(opID, dpID) {
    return axios.post(publicFn.getUrl('UpdateOC'), {
        "token":{"sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"},
        o: {
            "OCExpertId": store.state.users.employees.ExpertId,
            "OCOppointmentId": opID,
            "OCDeptId": dpID,
            OCSent: 0,
        }
    })
}


//获取患者列表记录getCustomerAppointmentList
function getCustomerAppointmentList(state='', type='', name='', exid='', time = [publicFn.getTime(-10), publicFn.getTime(10)]) {
    // if (!time) time = [publicFn.getTime(-10), publicFn.getTime(10)]
    return axios.post(publicFn.getUrl('getCustomerAppointmentList'), {
        "token":{"sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"},
        SearchCondition: {
            OppointmentShopId: store.state.bases.shop.ShopId,
            OppointmentTimeEnd: time[1],
            OppointmentState: state,
            OppointmentTimeStart: time[0],
            OppointmentType: type,
            CustomerName: name,
            OppointmentExpertId: exid
        },
        ReturnInfo: {
            CustomerFull: {},
            Expert: 1,
            Schedule: {}
        }
    })
}


//顾客备注保存
function csRemark (str) {
  return axios.post(publicFn.getUrl('UpdateCustomerCompanyRelation'), {
      "token":{
          "sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"
      },
      o: {
          CCRCompanyId: store.state.users.user.Company.CompanyId,
          CCRCustomerId: store.state.users.CsUser.CustomerId,
          CCRValue: str,
          CCRType: 'CustomerDes'
      }
  })
}


//获取顾客备注
function getCsRemark() {
    // console.log(store.state.users.CsUser.CustomerId)
    return axios.post(publicFn.getUrl('CustomerCompanyRelation_Get'), {
        "token":{
            "sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"
        },
        SearchCondition: {
            CCRCompanyId: store.state.users.user.Company.CompanyId,
            CCRCustomerId: store.state.users.CsUser.CustomerId,
            CCRType: 'CustomerDes'
        }
    })
}

//获取同意书
function getIcf() {
    return axios.post(publicFn.getUrl('ICF_GetList'), {
        "token":{
            "sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"
        },
        SearchCondition: {
            ICFReId: store.state.users.user.Company.CompanyId
        }
    })
}


//获取预约类型
function getYyType() {
    return axios.post(publicFn.getUrl('BusinessConfig_GetScheduleType'), {
        "token":{
            "sToken":"7BFBF54375D548B97044E7B702214ED2","TimeSpan":"1623390512"
        },
        "NeedSystem":"1",
        "SearchCondition":{
            "BCTypeId": store.state.users.user.Company.CompanyId,//公司id
            "BCKey":"CompanyScheduleType"
        }
    })
}

//ShopProduct_IsProductCanSellList获取商品全选
function ShopProduct_IsProductCanSellList(str) {
    return axios.post(publicFn.getUrl('ShopProduct_IsProductCanSellList'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        "SearchCondition":{
            "ProductId": str, //CHPProductId，英文逗号隔开
            "CompanyId": store.state.users.user.Company.CompanyId // 公司ID
    }})
}


export default {
    getShop,
    getDayCs,
    getAllCs,
    getCsConsulting,
    searchCs,
    getTriageList,
    getDept,
    callHao,
    getCustomerAppointmentList,
    csRemark,
    getCsRemark,
    getIcf,
    getYyType,
    ShopProduct_IsProductCanSellList
}
