//用户相关接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import md5 from 'js-md5';


//cs顾客用户登陆
function CsLanding (name, iphone) {
    return axios.post(publicFn.getUrl('Customer_Get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            "CustomerTelFull": iphone,
            "CustomerNameFull": name
        }
    })
}

//user门店用户登陆UserLogin
function userLing(name, pass) {
    let passMD5 = md5(pass, 32)
    let str = publicFn.randomString(10)
    return axios.post(publicFn.getUrl('UserLogin'), {
        // "UserCompanyType": "shop",
        "DeviceInfo": str,
        "UserName": name,
        "UserPWD": passMD5,
        "LoginLocation": "0.0"
    })
}

//获取顾客用户咨询记录GetListWithBusiness
function getCsConsulting() {
    // console.log(publicFn.getUrl('GetListWithBusiness'))
   return axios.post(publicFn.getUrl('GetListWithBusiness'), {
       token: {
           sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
           TimeSpan: '1618198660'
       },
       SearchCondition: {
           CompanyId: store.state.users.user.Company.CompanyId,
           CustomerId: store.state.users.CsUser.CustomerId,
           IndexMax: 0,
           IndexMin: 0,
           ShopId: store.state.bases.shop.ShopChannel == 'hospital' ? store.state.bases.shop.ShopId : '',
           "SearchRange": store.state.bases.shop.ShopChannel == 'hospital' ? "shop" : 'group'
       }
   })
}

//获取顾客用户销售记录CustomerOrder_List
function getSalesList() {
    return axios.post(publicFn.getUrl('CustomerOrder_List'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            COCompanyId: store.state.users.user.Company.CompanyId,
            COCustomerId: store.state.users.CsUser.CustomerId,
            IndexMax: 0,
            IndexMin: 0,
            COShopId: store.state.bases.shop.ShopChannel == 'hospital' ? store.state.bases.shop.ShopId : '',
            "SearchRange":store.state.bases.shop.ShopChannel == 'hospital' ? 'shop' : 'group',
        }
    })
}

//获取顾客用户预约列表getCustomerAppointmentList
function getReservationList () {
    return axios.post(publicFn.getUrl('getCustomerAppointmentList'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            OppointmentShopId: store.state.bases.shop.ShopId,
            OppointmentCustomerId: store.state.users.CsUser.CustomerId,
            "OppointmentState":"expected",
            IndexMax: 0,
            IndexMin: 0
        },
        ReturnInfo: {
            CustomerFull: {},
            Expert: 1
        }
    })
}


//员工登陆接口
function EmployeesLing(tel, pass) {
    let passMD5 = md5(pass, 32)
    return axios.post(publicFn.getUrl('expertLogin'), {
        ExpertTel: tel,
        ExpertPWD: passMD5.toUpperCase(),
        ExpertDeviceInfo: '',
        DeviceInfo: '',
        "bNeedChat":0,
        "ExpertLoginState": "shop"
    })
}

// 员工用户账号获取接口
function getEmployeesList(type = '', isxszzys = '', fcid='') {
    let shopid = store.state.bases.shop?store.state.bases.shop.ShopId:''
    return axios.post(publicFn.getUrl('Expert_GetList'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            IndexMin: 0,
            IndexMax: 0,
            ExpertShopId: shopid,
            ExpertCompanyId: store.state.users.user.UserReId,
            // "ExpertRole":"optometrist,expert,seller"
            "ExpertIsOperation": isxszzys,//增加这行
            ExpertECCId: fcid
        },
        ReturnInfo: {}
    })
}
//获取专家诊室呼叫中心医生
function getEmployeesListLT(fcid='', gzdw='',zc='', zy='') {
    return axios.post(publicFn.getUrl('Expert_GetList'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            IndexMin: 0,
            IndexMax: 0,
            ExpertECCId: fcid,
            ExpertCompany: gzdw,
            ExpertWorkTitle: zc,
            ExpertExpertise: zy,
            ExpertIsPublic:1
        },
        ReturnInfo: {

        },
    })
}

//获取医生各种筛选条件的下拉
// Expert_GetExpertWorkTitle：职称
// Expert_GetExpertEducation：学位
// Expert_GetExpertRole：角色
// Expert_GetExpertExpertise：擅长
// 另一个总的
// Expert_GetExpertConfig
function getEXSX(key = 'Expert_GetExpertConfig') {
    return axios.post(publicFn.getUrl(key), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        }
    })
}

//用户注册接口
function csZc(name, iph, sex, sr, idx, arid, adds) {
    return axios.post(publicFn.getUrl('Customer_Login190826'), {
        AutoReg: 1,
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            CustomerTelFull: iph,
            ChooseUserId: store.state.users.user.UserId,
            ChooseCompanyId: store.state.users.user.Company.CompanyId,
            CustomerNameFull: name,
            ChooseShopId: store.state.bases.shop.ShopId,
            CustomerGender: sex,
            CustomerBirthday: sr
        },
        CustomerAreaIndex:idx,
        CustomerAreaId: arid,
        CustomerAddress: adds
    })
}

//扫码登录
function codeLing(id) {
    let cid = store.state.users.user.Company.CompanyId
    return axios.post(publicFn.getUrl('LoginOnCustomerNo'), {
        "token":
            {
                "sToken": "5C8A6AE9F5214DF47A6E7152C26306A3",
                "TimeSpan": "1622179824"
            },
            "CustomerNo": id,
            "ChooseCompanyId":cid
    })
}


// 更新CS用户
function upCs(data) {
    console.log(data)
    return axios.post(publicFn.getUrl('Customer_Update'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        "o": data
    })
}

// 根据用户输入的用户名称或者号码查找用户信息 CustomerNameFull,CustomerTelFull
function getCsList(types, val) {
    let obj = {
        "IndexMin" : 0,    "IndexMax" : 0,
        ChooseShopId: store.state.bases.shop.ShopId
    }
    obj[types] = val
    // console.log(obj)
    return axios.post(publicFn.getUrl("getCustomerList"), {
        "token" : {    "sToken" : "622BA83BAFA74ABA563A657B8FB462C6",    "TimeSpan" : "1659510447"  },
        "SearchCondition" : obj
    })
}


//获取积分记录
function getIntegral() {
    return axios.post(publicFn.getUrl('CIH_GetList'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        "SearchCondition":{
            "CIHCompanyId": store.state.users.user.Company.CompanyId,//公司Id
            "CIHCustomerId": store.state.users.CsUser.CustomerId//CustomerId
        }
    })
}

//更用户来源
function upCSLY(str) {
    return axios.post(publicFn.getUrl('Customer_UpdateSource'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        CustomerId: store.state.users.CsUser.CustomerId,
        Source: str,
    })
}



//添加客户回访
function CustomerReview_Insert(data, url='CustomerReview_Insert') {
    return axios.post(publicFn.getUrl(url), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        o: data
    })
}

//获取顾客的回访列表
function CustomerReview_GetList(id) {
    return axios.post(publicFn.getUrl('CustomerReview_GetList'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        "SearchCondition": {
            "CRCustomerId":id,//必填
            "CRCompanyId": store.state.users.user.Company.CompanyId//必填
        },
        "ReturnInfo":{
            "ExpertName":1//必填
        }
    })
}


// 更新咨询时间Choose_UpdateChooseInfo
function Choose_UpdateChooseInfo (time) {
    return axios.post(publicFn.getUrl('Choose_UpdateChooseInfo'), {
        "token":{
            "TimeSpan":1559224299,
            "sToken":"6F028F43520E00EA96B616DE53AC2D4B"
        },
        "ChooseId": store.state.physicianVisits.xzConsulting,
        "AddTime": time,
        "ShopId": store.state.bases.shop.ShopId,
        "ExpertId": store.state.users.employees.ExpertId
    })
}


function getCSly() {
    return axios.post(publicFn.getUrl('Dic_GetList'), {
        "token": {
            "TimeSpan": 1716885697,
            "sToken": "37ED6AF8BBD3953E492E4C47309F426C"
        },
        "SearchCondition": {
            "CompanyId": store.state.users.user.Company.CompanyId,//填入真实的公司id
            "Code": null,
            "CodeFull": null,
            "Name": null,
            "Status": null,
            "ParentId": null,
            "ParentCode": "CSource",//客户来源固定填这个字符串
            "PageNo": null,
            "PageSize": null
        },
        "source":"CRM"
    })
}


function getTjr() {
    return axios.post(publicFn.getUrl("CustomerCompanyRelation_GetCustomerDistribution"), {
        "token": {
            "TimeSpan": "522130593000",
            "sToken": "BA156A744FB96E3B9B45EC2D8F0AAE5"
        },
        "CustomerId": store.state.users.CsUser.CustomerId,
        "CompanyId": store.state.users.user.Company.CompanyId,
        "DistributionGrade": 1
    })
}


function getCsTjr(id, tel) {
    return axios.post(publicFn.getUrl('Customer_GetList'), {
        "token": {
            "TimeSpan": "522130593000",
            "sToken": "BA156A744FB96E3B9B45EC2D8F0AAE5"
        },
        "SearchCondition": {
            "CustomerTelFull": tel,
            "CustomerId": id
        }
    })
}

function upTjr(id) {
    return axios.post(publicFn.getUrl("CustomerCompanyRelation_Update"), {
        "token": {
            "TimeSpan": "522130593000",
            "sToken": "BA156A744FB96E3B9B45EC2D8F0AAE5"
        },
        "o": {
            "CCRCustomerId": store.state.users.CsUser.CustomerId,
            "CCRCompanyId": store.state.users.user.Company.CompanyId,
            "CCRType": "CustomerDistribution",
            "CCRValue": id
        }
    })
}


export default {
    CsLanding,
    userLing,
    getCsConsulting,
    getSalesList,
    getReservationList,
    getEmployeesList,
    EmployeesLing,
    csZc,
    codeLing,
    upCs,
    getIntegral,
    getCsList,
    upCSLY,
    CustomerReview_Insert,
    CustomerReview_GetList,
    getEmployeesListLT,
    getEXSX,
    Choose_UpdateChooseInfo,
    getCSly,
    getTjr,
    upTjr,
    getCsTjr
}
