<template>
  <div class="popularScience">
    <span class="wz" @click="statr" @mouseup="end">{{text}}</span>

    <el-dialog
        title=""
        :visible.sync="show"
        width="800px"
        center>
      <iframe class="znr" ref="prints" style="border: none;" :src="url"></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "popularScience",
  props: {
    text: {
      default:''
    },
    gjz: {
      default: ''
    }
  },
  data() {
    return {
      url: '',
      show: false,
      time: 0,
      otime: ''
    }
  },
  created() {
    let  url = this._getUrl('TermsExplanation')
    url = url + this.gjz + '.html'
    this.url = url
  },
  methods: {
    statr() {
      this.show = true
    },
    end() {
      clearTimeout(this.otime)
    }
  }
}
</script>

<style scoped lang="scss">
.popularScience {
  cursor: pointer;
}
.znr {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  overflow-y: auto;
}
.wz {
  border-bottom: 1px solid #0000ce;
}
</style>

