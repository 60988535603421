import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import {Loading,MessageBox} from "element-ui"
import upFile from "../components/CommonComponents/upFile";



// 通用的PS_all_Normal_getList接口
function PS_all_Normal_getList(t, m) {
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type:  t,
            S_OP_Model:m,
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.GetListResult && res.GetListResult.length) {
                for (let i = 0; i < res.GetListResult.length; i++) {
                    res.GetListResult[i].S_OP_Json = publicFn.decoding(res.GetListResult[i].S_OP_Json)
                }
                return res.GetListResult
            }else {
                return ''
            }
        })
}

// 通用的S_ALL_Normal_Save接口
function S_ALL_Normal_Save(data) {
    if (!data) return false
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    let obj = JSON.parse(JSON.stringify(data))
    obj.S_OP_Json = publicFn.conversionJson(obj.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: obj
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                MessageBox('保存成功', '提示')
            }else {
                MessageBox('保存失败', '提示')
            }

        })
}



//健康报告是否可以查询接口
function isText (key, val) {
    let type = 'ChoosebShowClinicRecordToCustomer', o = {
        ChooseId: store.state.physicianVisits.xzConsulting,
    }
    if (key == 'isReportCanShare') type = 'ChoosebShowReportToCustomer'
    o[type] = val
  axios.post(publicFn.getUrl(key), {
      "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      o: o
  })
      .then(res => {
          // console.log(res)
          if (res.tr) {
              let csobj = store.state.users.csObj
              if (key == 'isClinicRecordCanShare') {
                  csobj.ChoosebShowClinicRecordToCustomer = val
              }else {
                  csobj.ChoosebShowReportToCustomer = val
              }
              store.commit('users/upCsObj', JSON.parse(JSON.stringify(csobj)))
          }
      })
}


//获取报告与病例的状态
function getBlBg() {
  return axios.post(publicFn.getUrl('getContentWithChooseID_single'), {
      "token": {
          "sToken": "301F39D31879DAB4F4416D31B4801C27",
          "TimeSpan": "1630915754"
      },
      SearchCondition: {
          ChooseId: store.state.physicianVisits.xzConsulting
      }
  })
}


//获取百度OCR的魔板ID
function getOCRType(type) {
  return axios.post(publicFn.getUrl('BDOcr_GetList'), {
      "token": {
          "sToken": "301F39D31879DAB4F4416D31B4801C27",
          "TimeSpan": "1630915754"
      },
      "SearchCondition": {
          "IndexMin": "0",
          "IndexMax": "0",
          "OPType": type,
          ShopId: store.state.bases.shop.ShopId
      }
  })
}

//获取用户历史图片
function getAllImg() {

}

//获取OSS图片列表
function getImgList(type, id=store.state.physicianVisits.xzConsulting) {
    // console.log(publicFn.getUrl('UploadFile_GetList'))
    return axios.post(publicFn.getUrl('UploadFile_GetList'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition: {
            UFReId: id,
            UFType: type,
            // UFSaveType: ''
        }
    })
}

//删除图片
function deleImg(id) {
    return axios.post(publicFn.getUrl('UploadFile_DeleteById'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        Id: id,
        "bDeleteFile": 1
    })
}


//百度图片请求次数BDOcr_InsertDetail
function addBDOCR(mbId, mbName, temId) {
    return axios.post(publicFn.getUrl('BDOcr_InsertDetail'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        o: {
            "OCRDetail_BDOCRId": mbId,
            "OCRDetail_TempName": mbName,
            "OCRDetail_CompanyId": store.state.users.user.Company.CompanyId,
            "OCRDetail_ShopId": store.state.bases.shop.ShopId,
            OCRDetail_TempId: temId
        }
    })
}


// 识别扣费第一步
function OCRLfirst(name, id) {
    return axios.post(publicFn.getUrl('Consume_Insert'), {
        "token": {
            "TimeSpan": 1602473240,
            "sToken": "60666C4259CF9C06E22CCAB86A74F3CB"
        },
        "o": {
            "CompanyConsume_CompanyId": store.state.users.user.Company.CompanyId,
            "CompanyConsume_ShopId": store.state.bases.shop.ShopId,
            "CompanyConsume_ChooseId": store.state.physicianVisits.xzConsulting,
            "CompanyConsume_HealthCheckId": "",
            "CompanyConsume_ExpertId": store.state.users.employees?store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000",
            "CompanyConsume_CustomerId": store.state.users.CsUser.CustomerId,
            "CompanyConsume_Type": "OCR",
            "CompanyConsume_TypeReId": id,
            "CompanyConsume_Content": name,
            "CompanyConsume_Status": "pre"
        }
    })
}

// 识别扣费第二步
function OCRsecond(id, type) {
    return axios.post(publicFn.getUrl('Consume_UpdateStatus'), {
        "token": {
            "TimeSpan": 1602473240,
            "sToken": "60666C4259CF9C06E22CCAB86A74F3CB"
        },
        "Id": id,
        "Succ": type,
        "Reason": "识别成功"
    })
}

//获取筛查列表HealthCheck_GetList
function getHealthCheckList() {
    return axios.post(publicFn.getUrl('HealthCheck_GetList'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition :{
            IndexMin: 1,
            IndexMax: 1000,
            // ShopId: store.state.bases.shop.ShopId,
            CustomerId: store.state.users.CsUser.CustomerId,
            CompanyId: store.state.users.user.Company.CompanyId
        }
    })
}


//新增筛查HealthCheck_Insert
function HealthCheckInsert (id='') {
    return axios.post(publicFn.getUrl('HealthCheck_Insert'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        o :{
            HealthCheckCustomerId: store.state.users.CsUser.CustomerId,
            HealthCheckCreateExpertId: store.state.users.employees.ExpertId,
            HealthCheckCreateShopId: store.state.bases.shop.ShopId,
            "ChooseUserId": store.state.users.user.UserId,
            "ChooseCompanyId": store.state.users.user.Company.CompanyId,
            "ChooseExpertId": "00000000-0000-0000-0000-000000000000",
            "ChooseParentId": "00000000-0000-0000-0000-000000000000",
            "ChooseHealthCheckId": id,//加这行，填入 对应的HealthCheckId
        }
    })
}

//获取单个筛查的接口HealthCheck_Get
function getHealthCheck(id) {
    return axios.post(publicFn.getUrl('HealthCheck_Get'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition :{
            HealthCheckId: id
        },
        "ReturnInfo": {
            "ListS_OP_All":true,
            "ListFile":true
        }
    })
}

//商品交付商品数据
function commodityJf(id) {
    return axios.post('https://interface2.visumall.com/Customer/CustomerOrder.ashx?cmd=Get', {
        "SearchCondition": {
            "COrderId": id,
            "bNeedDetailList":1
        },
        "ReturnInfo": {
            "CustomerFull": {},
            "CentralizedC": true,
            "ChooseFull":{},
            "CustomerOrderDetail":{
                "ProductRelation":{},
                "Oppointment":{}
            },
            ShopFull: {}
        },
        "token": {
            "TimeSpan": "1535942069232",
            "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89"
        }
    })
}

//获取库存
function getKc(id) {
    return axios.post(publicFn.getUrl('ShopProduct_GetList'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition: {
            "ProductId": id,
            "CompanyId":store.state.users.user.Company.CompanyId,
            "SPbLock":0
        },
        "ReturnInfo":{
            "ShopName":1
        }
    })
}

//查看能不能卖
function getIsm(id) {
    return axios.post(publicFn.getUrl('ShopProduct_IsProductCanSell'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition: {
            "ProductId": id,
            "CompanyId":store.state.users.user.Company.CompanyId
        }
    })
}


//提交时间系统
function addTime(keys, outTimes) {
    if (!store.state.users.employees) {
        return
    }
    axios.post(publicFn.getUrl('Operatefun_insert'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        o: {
            "EOFCompanyId": store.state.users.user.Company.CompanyId,
            "EOFExpertId": store.state.users.employees.ExpertId,
            EOFChooseId: store.state.physicianVisits.xzConsulting ? store.state.physicianVisits.xzConsulting : '00000000-0000-0000-0000-000000000000',
            EOFUserId: store.state.users.user.UserId,
            EOFShopId: store.state.bases.shop.ShopId,
            EOFCustomerId: store.state.users.CsUser.CustomerId,
            EOFOpTime: Number(outTimes.toFixed(0)),
            EOFKey: keys
        }
    })
        .then(res => {
            // console.log(res)
        })
}

//获取检查是否已经完成
function getExaminationFinish() {
    return axios.post(publicFn.getUrl('getContentWithChooseID_single'), {
        "token": {
            "sToken": "301F39D31879DAB4F4416D31B4801C27",
            "TimeSpan": "1630915754"
        },
        SearchCondition: {
            "ChooseId": store.state.physicianVisits.xzConsulting
        }
    })
}


// HisInfo : 戴镜史(视力检查)
// EyesCheck : 眼部检查
// Refractive  : 医学验光
// Inspection: 检查结论(VC门店版特有项次)
// VisionFunction: 视功能检查
// CL_RE_IP: 眼压
// CL_RE_SL: 裂隙灯
// CL_RE_FC: 眼底相机
// CL_RE_MG: 睑板腺
// CL_RE_CTM: 角膜地形图
// CL_RE_OM: 眼生物测量
// CL_RE_SM: 角膜内皮镜
// CL_RE_TFE: 泪膜检查
// CL_RE_SYN: 同视机
// CL_RE_VF: 视野计
// CL_RE_OCT: OCT
// CL_RE_CS: 对比敏感度
// CL_RE_WI: 像差分析仪

//获取这个时间段有排班的医生列表
function getScheduleDoctorList(time) {
    return axios.post(publicFn.getUrl('Schedule_GetExpertListInSchedules'), {
        "token":{
            "TimeSpan":1517328686398,"sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition":{
            "IndexMin": 0,
            "IndexMax": 0,
            "PageIndex": 1,
            "PageSize": 20,
            ScheduleShopId: store.state.bases.shop.ShopId,
            ScheduleDateStart: time,
            ScheduleDateEnd: time
        },
        "ReturnInfo": {
            "Expert": {}
        }
    })
}

//获取医生的时间
function getDoctorScheduleList(id, time) {
    return axios.post(publicFn.getUrl('Schedule_GetList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            "IndexMin": 0,
            "IndexMax": 0,
            "PageIndex": 1,
            "PageSize": 100,
            "ScheduleShopId": store.state.bases.shop.ShopId,
            "ScheduleExpertId": id,
            ScheduleDateStart: time,
            ScheduleDateEnd: time
        },
        ReturnInfo: {
            ShopFull: {}
        }
    })
}

//顾客预约
function insertOppointment(exid, csid, ScheduleId, opid, time) {
    return axios.post(publicFn.getUrl('insertOppointment'), {
        "token": {
            "TimeSpan": 1593173637,
            "sToken": "DBC7F06D27BA7F2FB54D5D63F11378D1"
        },
        "o": {
            "OppointmentId": "00000000-0000-0000-0000-000000000000",//固定值
            "OppointmentShopId": store.state.bases.shop.ShopId,
            "OppointmentExpertId": exid,
            "OppointmentCustomerId": csid,//预约看病的那个人的
            "OppointmentScheduleId": ScheduleId,
            "OppointmentTargetChooseId": "00000000-0000-0000-0000-000000000000",
            "OppointmentState": "expected",//固定
            "OppointmentOpenId": opid,//操作预约的那个人的
            "OppointmentTime": time,
            "OppointmentAddTime": publicFn.getTime(0),
            "OppointmentDoneTime": "",
            "OppointmentType": "WeiXinOppointment",
            "OppointmentSendTime": 999,//固定值
            "OppointmentWaitTime": "",
            "OppointmentNumber": 0,//固定值
            "Customer": null,
            "Company": null,
            "Shop": null,
            "Expert": null,
            "Schedule": null,
            "OppointmentEditeTime": "",
            "OppointmentEditeUserid": "00000000-0000-0000-0000-000000000000",//固定值
            "OppointmentRemarks": "",
            "OppointmentPayState": "",
            "Oppointmentrice": 0.0
        }
    })
}


function upFiles(bas64, type, name='.jpg') {
    return  axios.post(publicFn.getUrl('UploadFile_Upload'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            FileBase64:  bas64,
            ExtensionName: name,
            UFSaveType: 'OSSPrivate',
            UFReObject: 'Choose',
            UFType: type,
            UFReId: store.state.physicianVisits.xzConsulting
        }
    })
        .then(r => {
            if (r.Id) {
                return {
                    name: r.Id,
                    url: r.FullURL
                }
            }else {
                return ''
            }
        })
}

function getLsImg (type) {
    return  axios.post(publicFn.getUrl('UploadFile_GetListByCompanyCustomerType'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            "UFType": type,//类别
            "UFCustomerId":  store.state.users.CsUser.CustomerId,//顾客CustomerId
            "UFCompanyId": store.state.users.user.Company.CompanyId//公司CompanyId
        }
    })
}


function getAddress() {
    return axios.post(publicFn.getUrl('Area_GetListStruct'), {
        "token":{
            "TimeSpan":532618542085,
            "sToken":"532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        "AreaParentId": "00000000-0000-0000-0000-000000000000"
    })
}

function getListStruct(id) {
    return axios.post(publicFn.getUrl('Area_GetListStruct'), {
        "token":{
            "TimeSpan":532618542085,
            "sToken":"532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        AreaId: id
    })
}



function getCsSign() {
    return axios.post('https://interface2.visumall.com/UploadFile.ashx?cmd=Get', {
        "token": {
            "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
            "TimeSpan": "1535942069232"
        },
        "from": "AskVisumall_H5",
        "SearchCondition": {
            "UFReId":store.state.users.CsUser.CustomerId,
            "UFType": "CustomerSign",
            "UFReObject": "Customer",
        }
    })
}




export default {
    isText,
    getOCRType,
    getImgList,
    deleImg,
    getHealthCheckList,
    HealthCheckInsert,
    getHealthCheck,
    addBDOCR,
    getBlBg,
    commodityJf,
    getKc,
    getIsm,
    addTime,
    OCRLfirst,
    OCRsecond,
    getExaminationFinish,
    getScheduleDoctorList,
    getDoctorScheduleList,
    insertOppointment,
    upFiles,
    getLsImg,
    getAddress,
    getListStruct,
    PS_all_Normal_getList,
    S_ALL_Normal_Save,
    getCsSign
}
