import da from "element-ui/src/locale/lang/da";

let state = {
    tjkIdx: 0, //特检科菜单IDX

    datas: {
        S_OP_HI_ChooseID: '',
        S_OP_HI_Frame_note: '',
        S_OP_HI_invi_AxesOD: '',
        S_OP_HI_Frame_VAOD: '',
        S_OP_HI_WearTime: '',
        S_OP_HI_Frame_AxesOD: '',
        S_OP_HI_Frame_CYLOD: '',
        S_OP_HI_Frame_PDOD: '',
        S_OP_HI_Frame_SPHOS: '',
        S_OP_HI_isDone: '',
        S_OP_HI_invi_AxesOS: '',
        S_OP_HI_UCVA_OD: '',
        S_OP_HI_UCVA_OS: '',
        S_OP_HI_invi_VAOD: '',
        S_OP_HI_UCVA_OU: '',
        S_OP_HI_Frame_CYLOS: '',
        S_OP_HI_invi_note: '',
        S_OP_HI_Far_SCOS: '',
        S_OP_HI_Frame_AxesOS: '',
        S_OP_HI_Frame_AddOD: '',
        S_OP_HI_VA_OU: '',
        S_OP_HI_IfGlasses: '',
        S_OP_HI_FDateTime: '',
        S_OP_HI_Far_SCOU: '',
        S_OP_HI_invi_SPHOS: '',
        S_OP_HI_invi_CYLOS: '',
        S_OP_HI_Far_SCOD: '',
        S_OP_HI_VA_OS: '',
        S_OP_HI_Frame_AddOS: '',
        S_OP_HI_invi_CYLOD: '',
        S_OP_HI_VA_OD: '',
        S_OP_HI_invi_SPHOD: '',
        S_OP_HI_Frame_VAOS: '',
        S_OP_HI_Frame_PDOS: '',
        S_OP_HI_invi_VAOS: '',
        S_OP_HI_Frame_SPHOD: '',
        S_OP_HI_NearVisionOld_OD: '',
        S_OP_HI_NearVisionOld_OS: ''
    },
    //视力检查数据

    baseTestData: {
        S_OP_EC_AnteroomOD: "清、深",
        S_OP_EC_AnteroomOS: "清、深",
        S_OP_EC_CampaignOD: "",
        S_OP_EC_CampaignOD_extra: "",
        S_OP_EC_CampaignOS: "",
        S_OP_EC_CampaignOS_extra: "",
        S_OP_EC_CheckType: "",
        S_OP_EC_ChooseId: "",
        S_OP_EC_CongestiveOD: "泪点位正",
        S_OP_EC_CongestiveOS: "泪点位正",
        S_OP_EC_CornealOD: "透明",
        S_OP_EC_CornealOS: "透明",
        S_OP_EC_CrystalOD: "透明",
        S_OP_EC_CrystalOS: "透明",
        S_OP_EC_GazePropertiesOD: "中心注视",
        S_OP_EC_GazePropertiesOS: "中心注视",
        S_OP_EC_IrisOD: "纹理清",
        S_OP_EC_IrisOS: "纹理清",
        S_OP_EC_NippleOD: "无充血、水肿",
        S_OP_EC_NippleOS: "无充血、水肿",
        S_OP_EC_Ophthalmoscope: "",
        S_OP_EC_OpticPapillaOD: "红色界清，C/D约0.3",
        S_OP_EC_OpticPapillaOS: "红色界清，C/D约0.3",
        S_OP_EC_PositionOD: "",
        S_OP_EC_PositionOD_extra: "",
        S_OP_EC_PositionOS: "",
        S_OP_EC_PositionOS_extra: "",
        S_OP_EC_PupilOD: "PERRL",
        S_OP_EC_PupilOS: "PERRL",
        S_OP_EC_Remark: "",
        S_OP_EC_Remark2: "",
        S_OP_EC_RetinaOD: "色正",
        S_OP_EC_RetinaOS: "色正",
        S_OP_EC_RetinaVeinOD: "A:V约2:3",
        S_OP_EC_RetinaVeinOS: "A:V约2:3",
        S_OP_EC_SurfaceEyeOD: "无倒睫，眼睑闭合完",
        S_OP_EC_SurfaceEyeOS: "无倒睫，眼睑闭合完",
        S_OP_EC_YellowSpotOD: "黄斑中心凹反光存",
        S_OP_EC_YellowSpotOS: "黄斑中心凹反光存",
        S_OP_EC_isDone: true,
        S_OP_EC_lacrimalOD: "无混浊",
        S_OP_EC_lacrimalOS: "无混浊",
        S_OP_EC_meibomianOD: "",
        S_OP_EC_meibomianOS: "",
        S_OP_EC_santong: ""
    },
    //眼部检查数据

    lxdata: {},

    refractiveData: {S_OP_Json: {}},
    //屈光检查数据

    tryData: [
        // {
        //     S_OP_R_T_ChooseId: "",
        //     S_OP_R_T_DVAOD: '',
        //     S_OP_R_T_BaseOS: '',
        //     S_OP_R_T_DCOS: '',
        //     S_OP_R_T_DSOD: '',
        //     S_OP_R_T_AddOS: '',
        //     S_OP_R_T_NearDistance: '',
        //     S_OP_R_T_XOS: '',
        //     S_OP_R_T_NearPDOS: '',
        //     S_OP_R_part3_isDone: '',
        //     S_OP_R_T_NVAOD: '',
        //     S_OP_R_T_XOD: '',
        //     S_OP_R_T_BaseOD: '',
        //     S_OP_R_T_DVAOS: '',
        //     S_OP_ID: '',
        //     S_OP_R_T_PrismOS: '',
        //     S_OP_R_T_DSOS: '',
        //     S_OP_R_T_PrismOD: '',
        //     S_OP_R_T_Remark: '',
        //     S_OP_R_T_AddOD: '',
        //     S_OP_R_T_DCOD: '',
        //     S_OP_R_T_Type: '',
        //     S_OP_R_T_FarPDOD: '',
        //     S_OP_R_T_FarPDOS: '',
        //     S_OP_R_T_NearPDOD: '',
        //     S_OP_R_T_Number: '',
        //     S_OP_R_T_NVAOS: '',
        //     S_OP_R_T_Confirm: ''
        // }
    ],
    // 框架试戴数据

    eyeYaTestData: {
        CL_RE_IP_Remark: '',
        CL_RE_IP_OD: '',
        CL_RE_IP_isDone: '',
        CL_RE_IP_ChooseId: '',
        CL_RE_IP_OS: ''
    },//眼压数据

    corneaMap: {
        CL_OP_CTM_ChooseId: "",
        CL_OP_CTM_OD_Cylinder: "",
        CL_OP_CTM_OD_Diameter: "",
        CL_OP_CTM_OD_Evalue: "",
        CL_OP_CTM_OD_Extra_Evalue: "",
        CL_OP_CTM_OD_FlatK1: "",
        CL_OP_CTM_OD_FlatK2: "",
        CL_OP_CTM_OD_PupilD: "",
        CL_OP_CTM_OD_SteepK1: "",
        CL_OP_CTM_OD_SteepK2: "",
        CL_OP_CTM_OS_Cylinder: "",
        CL_OP_CTM_OS_Diameter: "",
        CL_OP_CTM_OS_Evalue: "",
        CL_OP_CTM_OS_Extra_Evalue: "",
        CL_OP_CTM_OS_FlatK1: "",
        CL_OP_CTM_OS_FlatK2: "",
        CL_OP_CTM_OS_PupilD: "",
        CL_OP_CTM_OS_SteepK1: "",
        CL_OP_CTM_OS_SteepK2: "",
        CL_OP_CTM_Remarks: "",
        CL_OP_CTM_isDone: false
    },
    //角膜地形图数据

    eyeBiologyTest: {
        CL_RE_OM_ChooseId: "",
        CL_RE_OM_OD_CCT: "",
        CL_RE_OM_OD_DAC: "",
        CL_RE_OM_OD_DC: "",
        CL_RE_OM_OD_FlatK1: "",
        CL_RE_OM_OD_FlatK2: "",
        CL_RE_OM_OD_LI: "",
        CL_RE_OM_OD_OcularAxis: "",
        CL_RE_OM_OD_PD: "",
        CL_RE_OM_OD_SteepK1: "",
        CL_RE_OM_OD_SteepK2: "",
        CL_RE_OM_OD_TEL: "",
        CL_RE_OM_OS_CCT: "",
        CL_RE_OM_OS_DAC: "",
        CL_RE_OM_OS_DC: "",
        CL_RE_OM_OS_FlatK1: "",
        CL_RE_OM_OS_FlatK2: "",
        CL_RE_OM_OS_LI: "",
        CL_RE_OM_OS_OcularAxis: "",
        CL_RE_OM_OS_PD: "",
        CL_RE_OM_OS_SteepK1: "",
        CL_RE_OM_OS_SteepK2: "",
        CL_RE_OM_OS_TEL: "",
        CL_RE_OM_Remarks: "",
        CL_RE_OM_isDone: false
    },
    //眼生物测量数据

    corneaNPJ: {
        CL_RE_SM_ChooseId: "",
        CL_RE_SM_OD_AveCellArea: "",
        CL_RE_SM_OD_CV: "",
        CL_RE_SM_OD_CellDensity: "",
        CL_RE_SM_OD_HCA: "",
        CL_RE_SM_OS_AveCellArea: "",
        CL_RE_SM_OS_CV: "",
        CL_RE_SM_OS_CellDensity: "",
        CL_RE_SM_OS_HCA: "",
        CL_RE_SM_Remark: "",
        CL_RE_SM_isDone: true
    },
    //角膜内皮镜数据

    appearsTest: {
        CL_RE_TFE_ChooseId: "",
        CL_RE_TFE_OD_BUT: "",
        CL_RE_TFE_OD_Schirmer5: "",
        CL_RE_TFE_OS_BUT: "",
        CL_RE_TFE_OS_Schirmer5: "",
        CL_RE_TFE_Remark: "",
        CL_RE_TFE_isDone: true
    },
    //泪膜数据

    tsj: {
        CL_RE_SYN_ChooseId: "",
        CL_RE_SYN_Remark: "",
        CL_RE_SYN_center: "",
        CL_RE_SYN_cubic: "",
        CL_RE_SYN_diverge: "",
        CL_RE_SYN_drepress: "",
        CL_RE_SYN_fusion: "",
        CL_RE_SYN_gather: "",
        CL_RE_SYN_isDone: true,
        CL_RE_SYN_kappa_L: "",
        CL_RE_SYN_kappa_R: "",
        CL_RE_SYN_picture1: "",
        CL_RE_SYN_picture2: "",
        CL_RE_SYN_picture3: "",
        CL_RE_SYN_remarks: "",
        CL_RE_SYN_subjectVision: ""
    },
    //同视机数据

    fieldVision: {
        CL_RE_VF_ChooseId: "",
        CL_RE_VF_Remark: "",
        CL_RE_VF_isDone: true
    },
    // 视野数据

    eyeCamera: {
        CL_RE_VF_ChooseId: "",
        CL_RE_VF_Remark: "",
        CL_RE_VF_isDone: true
    },
    //眼底相机数据

    AberrationAnalyze: {
        CL_RE_WI_ChooseId: "",
        CL_RE_WI_Remark: "",
        CL_RE_WI_isDone: true
    },
    // 像差分析仪数据

    ViewFnTest: '',


    eyeTestState: {

    }
}


let getters = {

}

let actions = {

}

let mutations = {
    uplxd(state, data) {
        state.lxdata = JSON.parse(JSON.stringify(data))
    },
    upTjkIdx(state, idx) {
        state.tjkIdx = idx
    },

    upChooseId(state, id) {
        state.datas.S_OP_HI_ChooseID = id
    },
    upDatas(state, data) {
        state.datas = data
    },
    upBaseTestData(state, data) {
        // console.log(data)
        state.baseTestData = data
    },
    upBaseTestDataCid(state, id) {
        state.datas.S_OP_EC_ChooseID = id
    },
    upRefractive(state, data) {
        state.refractiveData = data
    },
    upRefractiveCid(state, id) {
        state.refractiveData.S_OP_R_ChooseId = id
    },
    upEyeYaTest(state, data) {
        state.eyeYaTestData =data
    },
    upEyeYaTestCid(state, id) {
        state.eyeYaTestData.CL_RE_IP_ChooseId = id
    },
    upTryData(state, data) {
        state.tryData = data
    },
    upEyeCamera(state, data) {
        state.eyeCamera = data
    },
    upCorneaMap(state, data) {
        state.corneaMap = data
    },
    upEyeBiologyTest(state, data) {
        state.eyeBiologyTest =  data
    },
    upCorneaNPJ(state, data) {
        state.corneaNPJ = data
    },
    upAppearsTest(state, data) {
        state.appearsTest  = data
    },
    upTsj(state, data) {
        state.tsj = data
    },
    upFieldVision(state, data) {
        state.fieldVision  = data
    },

    upAberrationAnalyze(state, data) {
        state.AberrationAnalyze = data
    },

    upViewFnTest(state, data) {
        state.ViewFnTest = data
    },

    upAllData(state, data) {
        state.tjkIdx = 0 //特检科菜单IDX

        state.datas= {
            S_OP_HI_ChooseID: '',
                S_OP_HI_Frame_note: '',
                S_OP_HI_invi_AxesOD: '',
                S_OP_HI_Frame_VAOD: '',
                S_OP_HI_WearTime: '',
                S_OP_HI_Frame_AxesOD: '',
                S_OP_HI_Frame_CYLOD: '',
                S_OP_HI_Frame_PDOD: '',
                S_OP_HI_Frame_SPHOS: '',
                S_OP_HI_isDone: '',
                S_OP_HI_invi_AxesOS: '',
                S_OP_HI_UCVA_OD: '',
                S_OP_HI_UCVA_OS: '',
                S_OP_HI_invi_VAOD: '',
                S_OP_HI_UCVA_OU: '',
                S_OP_HI_Frame_CYLOS: '',
                S_OP_HI_invi_note: '',
                S_OP_HI_Far_SCOS: '',
                S_OP_HI_Frame_AxesOS: '',
                S_OP_HI_Frame_AddOD: '',
                S_OP_HI_VA_OU: '',
                S_OP_HI_IfGlasses: '',
                S_OP_HI_FDateTime: '',
                S_OP_HI_Far_SCOU: '',
                S_OP_HI_invi_SPHOS: '',
                S_OP_HI_invi_CYLOS: '',
                S_OP_HI_Far_SCOD: '',
                S_OP_HI_VA_OS: '',
                S_OP_HI_Frame_AddOS: '',
                S_OP_HI_invi_CYLOD: '',
                S_OP_HI_VA_OD: '',
                S_OP_HI_invi_SPHOD: '',
                S_OP_HI_Frame_VAOS: '',
                S_OP_HI_Frame_PDOS: '',
                S_OP_HI_invi_VAOS: '',
                S_OP_HI_Frame_SPHOD: ''
        }
        //视力检查数据

        state.baseTestData = {
            S_OP_EC_AnteroomOD: "清、深",
            S_OP_EC_AnteroomOS: "清、深",
            S_OP_EC_CampaignOD: "",
            S_OP_EC_CampaignOD_extra: "",
            S_OP_EC_CampaignOS: "",
            S_OP_EC_CampaignOS_extra: "",
            S_OP_EC_CheckType: "",
            S_OP_EC_ChooseId: "",
            S_OP_EC_CongestiveOD: "泪点位正",
            S_OP_EC_CongestiveOS: "泪点位正",
            S_OP_EC_CornealOD: "透明",
            S_OP_EC_CornealOS: "透明",
            S_OP_EC_CrystalOD: "透明",
            S_OP_EC_CrystalOS: "透明",
            S_OP_EC_GazePropertiesOD: "中心注视",
            S_OP_EC_GazePropertiesOS: "中心注视",
            S_OP_EC_IrisOD: "纹理清",
            S_OP_EC_IrisOS: "纹理清",
            S_OP_EC_NippleOD: "无充血、水肿",
            S_OP_EC_NippleOS: "无充血、水肿",
            S_OP_EC_Ophthalmoscope: "",
            S_OP_EC_OpticPapillaOD: "红色界清，C/D约0.3",
            S_OP_EC_OpticPapillaOS: "红色界清，C/D约0.3",
            S_OP_EC_PositionOD: "",
            S_OP_EC_PositionOD_extra: "",
            S_OP_EC_PositionOS: "",
            S_OP_EC_PositionOS_extra: "",
            S_OP_EC_PupilOD: "PERRL",
            S_OP_EC_PupilOS: "PERRL",
            S_OP_EC_Remark: "",
            S_OP_EC_Remark2: "",
            S_OP_EC_RetinaOD: "色正",
            S_OP_EC_RetinaOS: "色正",
            S_OP_EC_RetinaVeinOD: "A:V约2:3",
            S_OP_EC_RetinaVeinOS: "A:V约2:3",
            S_OP_EC_SurfaceEyeOD: "无倒睫，眼睑闭合完",
            S_OP_EC_SurfaceEyeOS: "无倒睫，眼睑闭合完",
            S_OP_EC_YellowSpotOD: "黄斑中心凹反光存",
            S_OP_EC_YellowSpotOS: "黄斑中心凹反光存",
            S_OP_EC_isDone: true,
            S_OP_EC_lacrimalOD: "无混浊",
            S_OP_EC_lacrimalOS: "无混浊",
            S_OP_EC_meibomianOD: "",
            S_OP_EC_meibomianOS: "",
            S_OP_EC_santong: ""
        }
        //眼部检查数据

        state.refractiveData = {S_OP_Json: {}}
        //屈光检查数据

        state.tryData = [
            // {
            //     S_OP_R_T_ChooseId: "",
            //     S_OP_R_T_DVAOD: '',
            //     S_OP_R_T_BaseOS: '',
            //     S_OP_R_T_DCOS: '',
            //     S_OP_R_T_DSOD: '',
            //     S_OP_R_T_AddOS: '',
            //     S_OP_R_T_NearDistance: '',
            //     S_OP_R_T_XOS: '',
            //     S_OP_R_T_NearPDOS: '',
            //     S_OP_R_part3_isDone: '',
            //     S_OP_R_T_NVAOD: '',
            //     S_OP_R_T_XOD: '',
            //     S_OP_R_T_BaseOD: '',
            //     S_OP_R_T_DVAOS: '',
            //     S_OP_ID: '',
            //     S_OP_R_T_PrismOS: '',
            //     S_OP_R_T_DSOS: '',
            //     S_OP_R_T_PrismOD: '',
            //     S_OP_R_T_Remark: '',
            //     S_OP_R_T_AddOD: '',
            //     S_OP_R_T_DCOD: '',
            //     S_OP_R_T_Type: '',
            //     S_OP_R_T_FarPDOD: '',
            //     S_OP_R_T_FarPDOS: '',
            //     S_OP_R_T_NearPDOD: '',
            //     S_OP_R_T_Number: '',
            //     S_OP_R_T_NVAOS: '',
            //     S_OP_R_T_Confirm: ''
            // }
        ]
            // 框架试戴数据

        state.eyeYaTestData = {
            CL_RE_IP_Remark: '',
                CL_RE_IP_OD: '',
                CL_RE_IP_isDone: '',
                CL_RE_IP_ChooseId: '',
                CL_RE_IP_OS: ''
        }
        //眼压数据

        state.corneaMap = {
            CL_OP_CTM_ChooseId: "",
                CL_OP_CTM_OD_Cylinder: "",
                CL_OP_CTM_OD_Diameter: "",
                CL_OP_CTM_OD_Evalue: "",
                CL_OP_CTM_OD_Extra_Evalue: "",
                CL_OP_CTM_OD_FlatK1: "",
                CL_OP_CTM_OD_FlatK2: "",
                CL_OP_CTM_OD_PupilD: "",
                CL_OP_CTM_OD_SteepK1: "",
                CL_OP_CTM_OD_SteepK2: "",
                CL_OP_CTM_OS_Cylinder: "",
                CL_OP_CTM_OS_Diameter: "",
                CL_OP_CTM_OS_Evalue: "",
                CL_OP_CTM_OS_Extra_Evalue: "",
                CL_OP_CTM_OS_FlatK1: "",
                CL_OP_CTM_OS_FlatK2: "",
                CL_OP_CTM_OS_PupilD: "",
                CL_OP_CTM_OS_SteepK1: "",
                CL_OP_CTM_OS_SteepK2: "",
                CL_OP_CTM_Remarks: "",
                CL_OP_CTM_isDone: false
        }
        //角膜地形图数据

        state.eyeBiologyTest = {
            CL_RE_OM_ChooseId: "",
                CL_RE_OM_OD_CCT: "",
                CL_RE_OM_OD_DAC: "",
                CL_RE_OM_OD_DC: "",
                CL_RE_OM_OD_FlatK1: "",
                CL_RE_OM_OD_FlatK2: "",
                CL_RE_OM_OD_LI: "",
                CL_RE_OM_OD_OcularAxis: "",
                CL_RE_OM_OD_PD: "",
                CL_RE_OM_OD_SteepK1: "",
                CL_RE_OM_OD_SteepK2: "",
                CL_RE_OM_OD_TEL: "",
                CL_RE_OM_OS_CCT: "",
                CL_RE_OM_OS_DAC: "",
                CL_RE_OM_OS_DC: "",
                CL_RE_OM_OS_FlatK1: "",
                CL_RE_OM_OS_FlatK2: "",
                CL_RE_OM_OS_LI: "",
                CL_RE_OM_OS_OcularAxis: "",
                CL_RE_OM_OS_PD: "",
                CL_RE_OM_OS_SteepK1: "",
                CL_RE_OM_OS_SteepK2: "",
                CL_RE_OM_OS_TEL: "",
                CL_RE_OM_Remarks: "",
                CL_RE_OM_isDone: false
        }
        //眼生物测量数据

        state.corneaNPJ= {
            CL_RE_SM_ChooseId: "",
                CL_RE_SM_OD_AveCellArea: "",
                CL_RE_SM_OD_CV: "",
                CL_RE_SM_OD_CellDensity: "",
                CL_RE_SM_OD_HCA: "",
                CL_RE_SM_OS_AveCellArea: "",
                CL_RE_SM_OS_CV: "",
                CL_RE_SM_OS_CellDensity: "",
                CL_RE_SM_OS_HCA: "",
                CL_RE_SM_Remark: "",
                CL_RE_SM_isDone: true
        }
        //角膜内皮镜数据

        state.appearsTest= {
            CL_RE_TFE_ChooseId: "",
                CL_RE_TFE_OD_BUT: "",
                CL_RE_TFE_OD_Schirmer5: "",
                CL_RE_TFE_OS_BUT: "",
                CL_RE_TFE_OS_Schirmer5: "",
                CL_RE_TFE_Remark: "",
                CL_RE_TFE_isDone: true
        }
        //泪膜数据

        state.tsj= {
            CL_RE_SYN_ChooseId: "",
                CL_RE_SYN_Remark: "",
                CL_RE_SYN_center: "",
                CL_RE_SYN_cubic: "",
                CL_RE_SYN_diverge: "",
                CL_RE_SYN_drepress: "",
                CL_RE_SYN_fusion: "",
                CL_RE_SYN_gather: "",
                CL_RE_SYN_isDone: true,
                CL_RE_SYN_kappa_L: "",
                CL_RE_SYN_kappa_R: "",
                CL_RE_SYN_picture1: "",
                CL_RE_SYN_picture2: "",
                CL_RE_SYN_picture3: "",
                CL_RE_SYN_remarks: "",
                CL_RE_SYN_subjectVision: ""
        }
        //同视机数据

        state.fieldVision= {
            CL_RE_VF_ChooseId: "",
                CL_RE_VF_Remark: "",
                CL_RE_VF_isDone: true
        }
        // 视野数据

        state.eyeCamera= {
            CL_RE_VF_ChooseId: "",
                CL_RE_VF_Remark: "",
                CL_RE_VF_isDone: true
        }
        //眼底相机数据

        state.AberrationAnalyze= {
            CL_RE_WI_ChooseId: "",
                CL_RE_WI_Remark: "",
                CL_RE_WI_isDone: true
        }
        // 像差分析仪数据

        state.ViewFnTest= ''

        state.eyeTestState= {

        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
