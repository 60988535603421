import da from "element-ui/src/locale/lang/da";

let state = {
    tops: true,
    bottoms: false,
    iframeType: '',
    ishmd: true,
    keyh: ''
}

let getters = {

}

let actions = {

}

let mutations = {
    upTops(state, data) {
        state.tops = data
    },
    upBottom(state, data) {
        state.bottoms = data
    },
    upIframeType(state, data) {
        state.iframeType = data
    },
    upishmd(state, data) {
        state.ishmd = data
    },
    upkeyh(state, data) {
        state.keyh = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
