<template>
  <div id="app">
    <top v-if="topshow"></top>

      <router-view ></router-view>

    <Bottom v-if="bottomShow" class="buttomuu"></Bottom>

    <div class="zjzs" v-if="0" :class="isyc?'yc':''">
      <iframe class="znr" ref="prints" src="https://askvisumall.visumall.com/#/"></iframe>
      <div class="fn">
        <p @click="isyc=true">隐藏</p>
        <p>关闭</p>
      </div>
    </div>
  </div>
</template>

<script>
import landing from './components/landing.vue'
import top from "@/components/top";
import pf from "@/publicFn/baseFn"

export default {
  name: 'app',
  data() {
    return {
      isyc: false
    }
  },
  created() {
    // this.$store.commit('upBottom')
    // if (this.$route.path == '/') this.$store.commit('upTops', true)

    if (!pf.getUrlVal('companyid')) {
      return this.$alert('路径进入错误！请检查！', '提示')
    }


    if (this.$store.state.bases.urlArr.length > 0) {
      let arr = this.$store.state.bases.urlArr
      let item = new Map()
      for (let i = 0; i < arr.length; i++) {
        item.set(arr[i].IAKey, arr[i].IAValue)
      }
      this.$store.commit('bases/UpUrlMap', item)
    }else {
      pf.getURL()
    }
  },
  components: {
    landing,
    top,
    Bottom: () => import('@/components/bottom')
  },
  computed: {
    bottomShow() {
      return this.$store.state.isShow.bottoms
    },
    topshow() {
      return this.$store.state.isShow.tops
    }
  }
}
</script>

<style lang="scss">
.el-select__tags {
  overflow: hidden !important;
  height: 30px;
  flex-wrap: nowrap !important;
  justify-content: start !important;
  left: 5px !important;
  //padding-left: 3px !important;
}

@media print {

}

*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 15px;
}
p {margin: 0; padding: 0}
li {list-style: none}
a {text-decoration: none; color: #000000;}
/*.el-collapse-item__header {*/
/*  background-color: #0e2e66 !important;*/
/*  color:#ffffff !important;*/
/*  border: none  !important;*/
/*}*/
.el-button--primary {
  background-color: rgb(29, 53, 161) !important;
  border-color: rgb(29, 53, 161) !important;
}
.xhx {text-decoration:underline;}
.buttomuu {
  position: fixed;
  bottom: 0;
  left: 0;
}

.text-l {text-align: left}
/*.el-select__tags {*/
/*  width: 150px;*/
/*  height: 24px !important;*/
/*  overflow: hidden;*/
/*}*/
/*.el-select {*/
/*  width: 150px;*/
/*  height: 40px !important;*/
/*  overflow: hidden;*/
/*  overflow-y: auto;*/
/*}*/
.el-textarea__inner {
  color: #686868 !important;
}
.el-input.is-disabled .el-input__inner {
  color: #686868 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.zjzs {
  position: fixed;
  z-index: 999;
  width: 400px;
  height: 650px;
  padding-top: 30px;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
  .znr {
    width: 400px;
    height: 650px;
  }
  .fn {
    position: absolute;
    top: 0;
    right: 160px;
    display: flex;
    p {
      color: #FFFFFF;
      background: #ff0101;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
      margin: 0 10px;
      cursor: pointer;
    }
  }
}

.yc {
  bottom: -680px;
  left: -400px;
}


</style>
