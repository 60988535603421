export default [
    {
        path: '/training',
        component: () => import('@/views/Training/trainingPage'),
        children: [
            {
                path: '/',
                name: "training",
                component: () => import('@/views/Training/trainingHome'),
            },

            {
                path: '/training/trainingJudge',
                name: 'trainingJudge',
                component: () => import('@/views/Training/trainingJudge')
            },

            {
                path: '/training/trainingConclusion',
                name: 'trainingConclusion',
                component: () => import('@/views/Training/trainingConclusion')
            },

            {
                path: '/training/trainingPlan',
                name: 'trainingPlan',
                component: () => import('@/views/Training/trainingPlan')
            },

            {
                path: '/training/onlineTrainingHome',
                name: "onlineTrainingHome",
                component: () => import('@/views/Training/onlineTraining/onlineTrainingHome')
            },

            {
                path: '/training/onlineTrainingHome/trainingDiagnosis',
                name: "trainingDiagnosis",
                component: () => import('@/views/Training/onlineTraining/trainingDiagnosis')
            },

            {
                path: '/training/onlineTrainingHome/trainingCourse',
                name: "trainingCourse",
                component: () => import('@/views/Training/onlineTraining/trainingCourse')
            },
        ]
    }
]