export default [
    {
        path: '/PopularScienceHome',
        // name: 'totalDrive',
        component: () => import('@/views/proFunction/ysHome'),
        children: [
            {
                path: '/',
                name: 'PopularScienceDemo',
                component: () => import('@/views/proFunction/PopularScienceDemo')
            },

            {
                path: '/PopularScienceHome/proFunction',
                name: 'proFunction',
                component: () => import('@/views/proFunction/proFunction')
            },

            {
                path: '/PopularScienceHome/totalDrive',
                name: 'totalDrive',
                component: () => import('@/views/proFunction/totalDrive')
            },//驾驶

            {
                path: '/PopularScienceHome/visualTraining',
                name: 'visualTraining',
                component: () => import('@/views/disposalPlan/visualTraining')
            },//视觉训练

            {
                path: '/PopularScienceHome/frameMirror',
                name: 'frameMirror',
                component: () => import('@/views/disposalPlan/frameMirror')
            },//框架镜

            {
                path: '/PopularScienceHome/myopiaHazard',
                name: 'myopiaHazard',
                component: () => import('@/views/disposalPlan/myopiaHazard')
            },//高度近视的危害

            {
                path: '/PopularScienceHome/myopiaComplication',
                name: 'myopiaComplication',
                component: () => import('@/views/disposalPlan/myopiaComplication')
            },//高度近视并发症

            {
                path: '/PopularScienceHome/eyeHabit',
                name: 'eyeHabit',
                component: () => import('@/views/disposalPlan/eyeHabit')
            },//用眼习惯

            {
                path: '/PopularScienceHome/penPosture',
                name: 'penPosture',
                component: () => import('@/views/disposalPlan/penPosture')
            },//握笔姿势

            {
                path: '/PopularScienceHome/eyeEnvironment',
                name: 'eyeEnvironment',
                component: () => import('@/views/disposalPlan/eyeEnvironment')
            },//用眼环境

            {
                path: '/PopularScienceHome/growUp',
                name: 'growUp',
                component: () => import('@/views/disposalPlan/growUp')
            },//生长发育sceneEexperience

            {
                path: '/PopularScienceHome/visualChart',
                name: 'visualChart',
                component: () => import('@/views/disposalPlan/visualChart')
            },//儿童视力发育表

            {
                path: '/PopularScienceHome/pabulum',
                name: 'pabulum',
                component: () => import('@/views/disposalPlan/pabulum')
            },//营养

            {
                path: '/PopularScienceHome/polarized',
                name: 'polarized',
                component: () => import('@/views/proFunction/polarized')
            },//偏光

            {
                path: '/PopularScienceHome/polaPrinciple',
                name: 'polaPrinciple',
                component: () => import('@/views/proFunction/polaPrinciple')
            },//偏光原理
        ]
    }
]
