import store from '@/store/index'
import axios from "@/api/axios"
import { Loading,MessageBox } from 'element-ui'
import userCurd from '@/api/user'
import Company from '@/api/Company'
import physicianVisits from "@/api/physicianVisits";
import eyeTest from "@/api/eyeTest";
import el from "element-ui/src/locale/lang/el";
import expertClinic from "@/api/expertClinic";
import corneaContact from "@/api/corneaContact";
import publicApi from "@/api/publicApi";
import training from "@/api/training";


let ajaxPost = function (url, data) {
    return new Promise((res, rej) => {
        let xhr = new XMLHttpRequest();
        xhr.open("POST",url,true);
        xhr.setRequestHeader("Content-type","application/x-www-form-urlencoded");
        xhr.send(JSON.stringify(data));
        xhr.onreadystatechange = (e) => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                let datap = JSON.parse(xhr.responseText)
                res(datap)
            }
        }
    })
}


// axios.post('http://wsbusiness.visumall.com:8088/BusinessConfig.ashx?cmd=GetInterfaceAddress', {
//
// })



export default {
    userCurdApi: userCurd,
    Company,
    physicianVisits,
    eyeTest,
    expertClinic,
    corneaContact,
    publicApi,
    training
}
