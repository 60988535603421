//治疗训练中心接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'


//获取在线训练相关检查及诊断数据
function getTrainingDiagnosis(){
    return axios.post(publicFn.getUrl('S_ALL_Normal_Get'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        SearchCondition: {
            S_OP_Type: 'VisionTrainForTopEye',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存在线训练相关检查及诊断数据
function saveTrainingDiagnosis() {
    let datas = JSON.parse(JSON.stringify(store.state.training.trainingDiagnosis))
    datas.S_OP_Json = publicFn.conversionJson(datas.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        token: {
            sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
            TimeSpan: '1618198660'
        },
        o: datas
    })
}


//获取在线训练课时内容
function getCurriculum() {
  return axios.post(publicFn.getUrl('TopEye_recommend'), {
      ShopId: store.state.bases.shop.ShopId,
      ChooseId: store.state.physicianVisits.xzConsulting,
      CustomerId: store.state.users.CsUser.CustomerId,
      token: {
          sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
          TimeSpan: '1618198660'
      }
  })
}

//购买方案
function buyScheme() {
  return axios.post(publicFn.getUrl('CustomerOrder_DirectInsertCCP_TopEye'), {
      "token": {
          "sToken": "45B94332B4508AAE81295EB24C982654",
          "TimeSpan": "1623829629"
      },
      "CompanyId": store.state.users.user.Company.CompanyId,
      "PriceFinal": 0,
      "UserId": store.state.users.user.UserId,
      "PriceBefore": 0,
      "ShopId": store.state.bases.shop.ShopId,
      "CustomerId": store.state.users.CsUser.CustomerId,
      "ChooseProductId": "",
      "ChooseId": store.state.physicianVisits.xzConsulting,
      "CAPId": "",
      "ExpertId": store.state.users.employees.ExpertId
  })
}

//获取训练方案数据
function getPlanData() {
   return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
       "token": {
           "sToken": "45B94332B4508AAE81295EB24C982654",
           "TimeSpan": "1623829629"
       },
       SearchCondition: {
           S_OP_Type: 'InspectionTraining',
           ChooseId: store.state.physicianVisits.xzConsulting
       }
   })
}

//获取训练诊断的数据
function getTrainingJudge() {
  return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
      "token": {
          "sToken": "45B94332B4508AAE81295EB24C982654",
          "TimeSpan": "1623829629"
      },
      SearchCondition: {
          S_OP_Type: 'InspectionTraining',
          ChooseId: store.state.physicianVisits.xzConsulting,
          S_OP_Model: 'S_OP_InspectionTraining190222'
      }
  })
}

//获取训练诊断的数据
function getTrainingConclusion() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "sToken": "45B94332B4508AAE81295EB24C982654",
            "TimeSpan": "1623829629"
        },
        SearchCondition: {
            S_OP_Type: 'InspectionTrainingTwo',
            ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_Model: 'S_OP_InspectionTrainingTwo190606'
        }
    })
}

export default {
    getTrainingDiagnosis,
    saveTrainingDiagnosis,
    getCurriculum,
    buyScheme,
    getPlanData,
    getTrainingJudge,
    getTrainingConclusion
}
