//角膜接触镜所有接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import {Loading, MessageBox} from "element-ui";




//获取角膜试戴PS_all_Normal_getList
function getTryAssessment(model='S_OP_ContactGlass180726') {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'ContactGlass',
            S_OP_Model:model,
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}


//角膜试戴的保存接口S_ALL_Normal_SaveList
function saveTryAssessment(m='S_OP_ContactGlass180726') {
    if (!store.state.users.CsUser || !store.state.physicianVisits.xzConsulting) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    let arr = []
    if (store.state.corneaContact.tryCorneaContact) {
        console.log(store.state.corneaContact.tryCorneaContact)
        let item = JSON.parse(JSON.stringify(store.state.corneaContact.tryCorneaContact))
        arr = item.map(item => {
            item.S_OP_Json = publicFn.conversionJson(item.S_OP_Json)
            return item
        })
    }

    if (store.state.corneaContact.tryCorneaContact.length < 1) {
        return axios.post(publicFn.getUrl('S_OP_ALL_DeleteByType'), {
            "token": {
                "sToken": "45B94332B4508AAE81295EB24C982654",
                "TimeSpan": "1623829629"
            },
            ChooseId: store.state.physicianVisits.xzConsulting,
            "S_OP_Model":m,
            "S_OP_Type":"ContactGlass"
        })
            .then(res => {
                loadingInstance.close()
                if (res.DeleteByTypeResult) {
                    MessageBox(`保存成功`, '提示')
                }else {
                    MessageBox(`保存失败`, '提示')
                }
            })
    }

    return axios.post(publicFn.getUrl('S_all_Normal_SaveList') , {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        lo: arr
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                MessageBox(`保存成功`, '提示')
            }else {
                MessageBox(`保存失败`, '提示')
            }
        })
}

//获取订单的接口PS_all_Normal_getList
function getCorneaOrder() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_Order',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//获取角塑订单的购物车updateChooseList
function getCorneaShop() {
    return axios.post(publicFn.getUrl('updateChooseList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            CHPStatus: '1',
            CHPProductSortGroup: 'rgp',
            ChooseId: store.state.physicianVisits.xzConsulting
        },
        ReturnInfo: {
            ReturnInfo: {Product: {}}
        }
    })
}

//获取复查接口PS_all_Normal_getList
function getCorneaReview() {
   return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
       "token": {
           "TimeSpan": 1566827733,
           "sToken": "768DCAFFF869CD61372790C2738012EC"
       },
       SearchCondition: {
           S_OP_Type: 'CL_Recheck',
           ChooseId: store.state.physicianVisits.xzConsulting
       }
   })
}

//保存复查接口S_ALL_Normal_Save
function saveCorneaReview() {
    if (!store.state.users.CsUser && !store.state.physicianVisits.xzConsulting) {
        MessageBox('用户登陆并且选择咨询或者新建咨询以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    let ExpertId = '00000000-0000-0000-0000-000000000000'
    if (store.state.users.employees.ExpertId) ExpertId = store.state.users.employees.ExpertId
    // console.log({
    //     S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
    //     S_OP_ExpertId: ExpertId,
    //     S_OP_CustomerId: store.state.users.CsUser.CustomerId,
    //     S_OP_Type: 'CL_Recheck',
    //     S_OP_Model: 'CL_Recheck180726',
    //     S_OP_Json: store.state.corneaContact.corneaReview
    // })
    axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: ExpertId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_Recheck',
            S_OP_Model: 'CL_Recheck180726',
            S_OP_Json: publicFn.conversionJson(store.state.corneaContact.corneaReview)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                MessageBox(`${name}保存成功`, '提示')
            }else {
                MessageBox(`${name}保存失败`, '提示')
            }
        })
        .catch(err => {loadingInstance.close()})
}



export default {
    getTryAssessment,
    saveTryAssessment,
    getCorneaOrder,
    getCorneaShop,
    getCorneaReview,
    saveCorneaReview
}
