<template>
  <div class="upFile">
    <div v-if="isShowImgList" class="imgList" v-for="(item, idx) in imgList">
      <div class="mg">
        <i @click="showmg=true;dqurl=item.url" class="el-icon-circle-plus-outline" style="margin-right: 20px"></i>
        <i class="el-icon-delete" @click="dele(idx)"></i>
      </div>
      <img :src="item.url" alt="">
    </div>
    <div class="scp" v-if="isShowImgList">
      <span>上传图片</span>
      <input @change="scimg($event)" type="file" accept="image/jpeg,image/jpg,image/png,image/svg/"  multiple>
    </div>
    <el-button v-if="isShowImgList" style="margin-left: 10px;height: 130px;margin-bottom: 10px;" @click="upPDF" type="info">上传PDF</el-button>
    <el-button style="margin-left: 10px;height: 130px;margin-bottom: 10px;" @click="geslimg" :class="!isShowImgList?'ls':''" type="info">历史图片</el-button>

    <div class="maximg" v-if="showmg" @click="gb">
      <div class="fn">
        <i @click.stop="zoom+=0.05" class="el-icon-zoom-in"></i>
        <i @click.stop="zoom-=0.05" class="el-icon-zoom-out"></i>
        <i class="el-icon-close"></i>
      </div>
      <img @click.stop="" class="imgfd" :style="'transform: scale('+zoom+')'" :src="dqurl" alt="">
    </div>

    <div class="maximg" v-if="showlsimg" @click="gb" style="display: block;">
      <div class="fn">
        <i @click.stop="zoom+=0.05" class="el-icon-zoom-in"></i>
        <i @click.stop="zoom-=0.05" class="el-icon-zoom-out"></i>
        <i class="el-icon-close"></i>
      </div>

      <div class="lsimg">
        <div v-for="item in lsImgLsit" @click.stop="dqlsimg=item.FullURL">
          <img :src="item.FullURL" :class="item.FullURL == dqlsimg?'xzimg':''" alt="">
          <p style="text-align: center;color: #FFFFFF;">{{item.UFTimeString}}</p>
        </div>

      </div>
      <img @click.stop="" class="imgfd" :style="'transform: scale('+zoom+')'" v-if="dqlsimg" :src="dqlsimg" alt="">

    </div>

  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "upFile",
  props: {
    type: '',
    imglists: {
      type: Array(),
      default: () => []
    },
    isShowImgList: {
      default: () => true
    }
  },
  data() {
    return {
      zoom: 1,
      showmg: false,
      imgList: [],
      dqurl: '',
      types: '',
      lsImgLsit: [],
      showlsimg: false,
      dqlsimg: ''
    }
  },
  watch:{
    imglists: {
      deep: true,
      handler(n, o) {
        console.log(JSON.parse(JSON.stringify(n)))

        // this.imgList = this.imgList.concat(n);
        this.imgList.push(n[n.length-1])
        // console.log(this.imgList)
      }
    }
  },
  created() {
    // console.log(this.type)
    if (this.imglists.length) {
      this.imgList = [...this.imglists]
    }
    this.getimglist()
  },
  methods: {
    gb() {
      this.zoom =  1
      this.showmg = false
      this.dqurl = ''
      this.dqlsimg = ''
      this.showlsimg = false
    },
    scimg(e) {
      let files = e.target.files
      let types = this.type
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader()
        reader.readAsDataURL(files[i])

        reader.onload = () => {
          let bas64 = reader.result
          if (files[i].size < 1536000) {
            bas64 = bas64.split(',')[1]
            this.upwj(bas64)
          } else {
            let img = new Image();

            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');

            img.src = bas64

            img.onload = () => {
              // console.log(img.width)
              let w = img.width, h = img.height
              canvas.width = w
              canvas.height = h
              context.clearRect(0, 0, w, h)
              context.drawImage(img, 0, 0, w, h)
              bas64 = canvas.toDataURL('image/jpeg', 0.2);

              bas64 = bas64.split(',')[1]

              this.upwj(bas64)

              img = null
            }
          }
        }

      }
    },

    upwj(bas64) {
      // console.log(this.type)
      this._api.publicApi.upFiles(bas64, this.type)
      .then(res => {
        // console.log(res)
        if (res) {
          this.imgList.push(res)
        }
      })
      // this._http.post(pf.getUrl('UploadFile_Upload'), {
      //   "token": {
      //     "TimeSpan": 1566827733,
      //     "sToken": "768DCAFFF869CD61372790C2738012EC"
      //   },
      //   o: {
      //     FileBase64:  bas64,
      //     ExtensionName: ".jpeg",
      //     UFSaveType: 'OSSPrivate',
      //     UFReObject: 'Choose',
      //     UFType: 'CTM',
      //     UFReId: this.$store.state.physicianVisits.xzConsulting
      //   }
      // })
      //     .then(r => {
      //       if (r.Id) {
      //         this.imgList.push({
      //           name: r.Id,
      //           url: r.FullURL
      //         })
      //       }
      //     })
    },

    dele(idx) {
      // location.reload(true)
      let log = this.$loading({
        text: '删除中...'
      })
      this._api.publicApi.deleImg(this.imgList[idx].name)
          .then(res => {
            log.close()
            if (res.DeleteByIdReturn) {
              this.imgList.splice(idx, 1)
            }
          })
    },

    getimglist() {
      if (!this.type) return
      this._api.publicApi.getImgList(this.type)
          .then(res => {
            let arr = []
            if (res.GetListResult && res.GetListResult.length > 0) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                this.imgList.push({
                  name: res.GetListResult[i].UFId,
                  url: res.GetListResult[i].FullURL,
                })
              }
            }
          })
    },

    geslimg() {
      this.showlsimg = true
      this._api.publicApi.getLsImg(this.type)
      .then(res => {
        // console.log(res)
        if (res.GetListResult && res.GetListResult.length) {
          this.lsImgLsit = [...res.GetListResult]
        }
      })
    },

    upPDF() {
      window.bound.pdfToImg()
      this.pdfHimg()
    },
    pdfHimg() {
      const _this =this
      window.pdfToImgResult = function (result) {
        if (result && result.ReportPages) {
          for (let i = 0; i < result.ReportPages.length; i++) {
            _this.upwj(result.ReportPages[i].PageBase64)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upFile {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
}
.scp {
  width: 130px;
  height: 130px;
  position: relative;
  border: 1px dashed #c6c6c6;
  border-radius: 5px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin-left: 10px;
  margin-bottom: 10px;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  i {
    font-size: 30px;
    font-weight: bold;
    color: #adadad;
  }
}
.scp:hover {
  color: #1eb4ff;
  border: 1px dashed #1eb4ff;
}
.imgList {
  position: relative;
  margin: 0 10px;
  margin-bottom: 10px;
  img {
    width: 130px;
    height: 130px;
    border-radius: 5px;
  }
  .mg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0,0,0,0.5);
    display: none;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 25px;
  }
  &:hover .mg{
    display: flex;
  }
}
.maximg {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.fn {
  color: #ff0000;
  font-size: 30px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1000;
  i {
    padding-right: 10px;
  }
}

//.imgbox {
//  width: 82vw;
//  margin: 0 auto;
//}

.imgfd {
  display: block;
  width: 80vw;
  height: auto;
  margin: 20px auto;
}

.lsimg {
  width: 90vw;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: auto;
  //margin-bottom: 10px;
  img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
  .xzimg {
    border: 2px solid #ff0101;
    border-radius: 5px;
  }
}
.ls {
  height: auto !important;
  margin-bottom: 0 !important;
}
</style>
