import el from "element-ui/src/locale/lang/el";

let state = {
    tryCorneaContact: '',
    isfcshowdata: 0,
    corneaReview: {
        CL_Recheck_ActivityOD: "",
        CL_Recheck_ActivityOS: "",
        CL_Recheck_CenterOD: "",
        CL_Recheck_CenterOS: "",
        CL_Recheck_ChooseId: "",
        CL_Recheck_D_DCOD: "",
        CL_Recheck_D_DCOS: "",
        CL_Recheck_D_DSOD: "",
        CL_Recheck_D_DSOS: "",
        CL_Recheck_D_VAOD: "",
        CL_Recheck_D_VAOS: "",
        CL_Recheck_D_XOD: "",
        CL_Recheck_D_XOS: "",
        CL_Recheck_EdgeOD: "",
        CL_Recheck_EdgeOS: "",
        CL_Recheck_HS_Cnkaite: "",
        CL_Recheck_HS_glass: "",
        CL_Recheck_HS_other: " ",
        CL_Recheck_HS_recheckTime: "",
        CL_Recheck_HS_stick: "",
        CL_Recheck_LYJHOD: "",
        CL_Recheck_LYJHOS: "",
        CL_Recheck_LocationOD: "",
        CL_Recheck_LocationOS: "",
        CL_Recheck_MatchingOD: "",
        CL_Recheck_MatchingOS: "",
        CL_Recheck_OnlandOD: "",
        CL_Recheck_OnlandOS: "",
        CL_Recheck_R_DCOD: "",
        CL_Recheck_R_DCOS: "",
        CL_Recheck_R_DSOD: "",
        CL_Recheck_R_DSOS: "",
        CL_Recheck_R_VAOD: "",
        CL_Recheck_R_VAOS: "",
        CL_Recheck_R_XOD: "",
        CL_Recheck_R_XOS: "",
        CL_Recheck_R_nakedOD: "",
        CL_Recheck_R_nakedOS: "",
        CL_Recheck_ReversalOD: "",
        CL_Recheck_ReversalOS: "",
        CL_Recheck_Say_Clear: "",
        CL_Recheck_Say_Comfort: "",
        CL_Recheck_Say_WearContinuously: "",
        CL_Recheck_Say_WearDuration: "",
        CL_Recheck_Say_WearedTime: "",
        CL_Recheck_Say_remark: "",
        CL_Recheck_Sur_level1OD: "",
        CL_Recheck_Sur_level1OS: "",
        CL_Recheck_Sur_level2OD: "",
        CL_Recheck_Sur_level2OS: "",
        CL_Recheck_Sur_precipitateOD: "",
        CL_Recheck_Sur_precipitateOS: "",
        CL_Recheck_Sur_timeOD: "",
        CL_Recheck_Sur_timeOS: "",
        CL_Recheck_WearingOD: "",
        CL_Recheck_WearingOS: "",
        CL_Recheck_doctorSay: "",
        CL_Recheck_para_AC_OD: "",
        CL_Recheck_para_AC_OS: "",
        CL_Recheck_para_DIA_OD: "",
        CL_Recheck_para_DIA_OS: "",
        CL_Recheck_para_RP_OD: "",
        CL_Recheck_para_RP_OS: "",
        CL_Recheck_para_remark_OD: "",
        CL_Recheck_para_remark_OS: "",
        CL_Recheck_picPath_OD: "",
        CL_Recheck_picPath_OS: "",
        XL_Recheck_D_DCOD: "",
        XL_Recheck_D_DCOS: "",
        XL_Recheck_D_DSOD: "",
        XL_Recheck_D_DSOS: "",
        XL_Recheck_D_VAOD: "",
        XL_Recheck_D_VAOS: "",
        XL_Recheck_D_XOD: "",
        XL_Recheck_D_XOS: "",
        XL_Recheck_R_DCOD: "",
        XL_Recheck_R_DCOS: "",
        XL_Recheck_R_DSOD: "",
        XL_Recheck_R_DSOS: "",
        XL_Recheck_R_VAOD: "",
        XL_Recheck_R_VAOS: "",
        XL_Recheck_R_XOD: "",
        XL_Recheck_R_XOS: "",
        XL_Recheck_R_correctionOD: "",
        XL_Recheck_R_correctionOS: "",
    },

    jqyptbsj: {},

    jqyb: '',
    jqybyb: '',
    jqtjsj: '',
}

let getters = {

}

let actions = {

}

let mutations = {
    upjqsdp(state, data) {
        state[data.key] = JSON.parse(JSON.stringify(data.val))
        // console.log(data)
        if (data.key === 'jqyb') {
            if (data.yb === 'DL') {
                state.jqybyb = ''
                state[data.key] = {}
            }else {
                state.jqybyb = data.yb
            }
        }
    },
    upjqyptbsj(state, data) {
        state.jqyptbsj = JSON.parse(JSON.stringify(data))
    },
    upTryCorneaContact(state, data) {
        state.tryCorneaContact = data
    },
    upCorneaReview(state, data) {
        for (let key in data) {
            state.corneaReview[key] = data[key]
        }
    },
    upAllData(state, data) {
        state.tryCorneaContact = ''
        state.corneaReview = {
            CL_Recheck_ActivityOD: "",
                CL_Recheck_ActivityOS: "",
                CL_Recheck_CenterOD: "",
                CL_Recheck_CenterOS: "",
                CL_Recheck_ChooseId: "",
                CL_Recheck_D_DCOD: "",
                CL_Recheck_D_DCOS: "",
                CL_Recheck_D_DSOD: "",
                CL_Recheck_D_DSOS: "",
                CL_Recheck_D_VAOD: "",
                CL_Recheck_D_VAOS: "",
                CL_Recheck_D_XOD: "",
                CL_Recheck_D_XOS: "",
                CL_Recheck_EdgeOD: "",
                CL_Recheck_EdgeOS: "",
                CL_Recheck_HS_Cnkaite: "",
                CL_Recheck_HS_glass: "",
                CL_Recheck_HS_other: " ",
                CL_Recheck_HS_recheckTime: "",
                CL_Recheck_HS_stick: "",
                CL_Recheck_LYJHOD: "",
                CL_Recheck_LYJHOS: "",
                CL_Recheck_LocationOD: "",
                CL_Recheck_LocationOS: "",
                CL_Recheck_MatchingOD: "",
                CL_Recheck_MatchingOS: "",
                CL_Recheck_OnlandOD: "",
                CL_Recheck_OnlandOS: "",
                CL_Recheck_R_DCOD: "",
                CL_Recheck_R_DCOS: "",
                CL_Recheck_R_DSOD: "",
                CL_Recheck_R_DSOS: "",
                CL_Recheck_R_VAOD: "",
                CL_Recheck_R_VAOS: "",
                CL_Recheck_R_XOD: "",
                CL_Recheck_R_XOS: "",
                CL_Recheck_R_nakedOD: "",
                CL_Recheck_R_nakedOS: "",
                CL_Recheck_ReversalOD: "",
                CL_Recheck_ReversalOS: "",
                CL_Recheck_Say_Clear: "",
                CL_Recheck_Say_Comfort: "",
                CL_Recheck_Say_WearContinuously: "",
                CL_Recheck_Say_WearDuration: "",
                CL_Recheck_Say_WearedTime: "",
                CL_Recheck_Say_remark: "",
                CL_Recheck_Sur_level1OD: "",
                CL_Recheck_Sur_level1OS: "",
                CL_Recheck_Sur_level2OD: "",
                CL_Recheck_Sur_level2OS: "",
                CL_Recheck_Sur_precipitateOD: "",
                CL_Recheck_Sur_precipitateOS: "",
                CL_Recheck_Sur_timeOD: "",
                CL_Recheck_Sur_timeOS: "",
                CL_Recheck_WearingOD: "",
                CL_Recheck_WearingOS: "",
                CL_Recheck_doctorSay: "",
                CL_Recheck_para_AC_OD: "",
                CL_Recheck_para_AC_OS: "",
                CL_Recheck_para_DIA_OD: "",
                CL_Recheck_para_DIA_OS: "",
                CL_Recheck_para_RP_OD: "",
                CL_Recheck_para_RP_OS: "",
                CL_Recheck_para_remark_OD: "",
                CL_Recheck_para_remark_OS: "",
                CL_Recheck_picPath_OD: "",
                CL_Recheck_picPath_OS: "",
                XL_Recheck_D_DCOD: "",
                XL_Recheck_D_DCOS: "",
                XL_Recheck_D_DSOD: "",
                XL_Recheck_D_DSOS: "",
                XL_Recheck_D_VAOD: "",
                XL_Recheck_D_VAOS: "",
                XL_Recheck_D_XOD: "",
                XL_Recheck_D_XOS: "",
                XL_Recheck_R_DCOD: "",
                XL_Recheck_R_DCOS: "",
                XL_Recheck_R_DSOD: "",
                XL_Recheck_R_DSOS: "",
                XL_Recheck_R_VAOD: "",
                XL_Recheck_R_VAOS: "",
                XL_Recheck_R_XOD: "",
                XL_Recheck_R_XOS: "",
                XL_Recheck_R_correctionOD: "",
                XL_Recheck_R_correctionOS: "",
               CL_Recheck_Sur_problem: ""
        }
    },

    upisfcshowdata(state, data) {
        state.isfcshowdata = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
