import store from "@/store/index";
import {Loading, MessageBox} from "element-ui";
import axios from "@/api/axios"
import da from "element-ui/src/locale/lang/da";

let TimeInit =  {
    customerLogin_registeration: {
        ENTER: 0,
        EXIT: 0
    },//"关注公众号/手机绑定/注册个信息/家庭账号"
    customerLogin_normal: {
        ENTER: 0,
        EXIT: 0
    },//姓名/电话模糊登录/扫码登录
    checkCustomerBills: {
        ENTER: 0,
        EXIT: 0
    },//查阅顾客历史消费
    checkCustomerOppointment: {
        ENTER: 0,
        EXIT: 0
    },//查询顾客预约详情

    inquiry_customerSay: {
        ENTER: 0,
        EXIT: 0
    },//问诊-顾客主观述求
    inquiry_customerGenderAndAge: {
        ENTER: 0,
        EXIT: 0
    },//问诊-性别年龄信息
    inquiry_specialNeeds: {
        ENTER: 0,
        EXIT: 0
    },//问诊-顾客特殊需求
    inquiry_mainScenes: {
        ENTER: 0,
        EXIT: 0
    },//问诊-用眼场景
    inquiry_distance: {
        ENTER: 0,
        EXIT: 0
    },//问诊-用眼距离
    inquiry_duration: {
        ENTER: 0,
        EXIT: 0
    },//问诊-用眼时长
    inquiry_diseasesHistory: {
        ENTER: 0,
        EXIT: 0
    },//问诊-眼健康病史

    EC_eyesightCheck: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视力检查-视力检查
    EC_oldGlassesCheck: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视力检查-旧镜情况
    EC_oldGlassesAnalyze: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视力检查-旧镜配戴分析
    EC_baseCheck: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-眼部检查-基础检查
    EC_fundusCheck: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-眼部检查-眼底检查
    EC_computerOptometry: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-屈光检查-电脑验光
    EC_subjectiveOptometry: {
        ENTER: 0,
        EXIT: 0
    },//"眼部检查-屈光检查-主观验光
    EC_sliceOptometry: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-屈光检查-插片验光/检影验光
    EC_eyesPosition: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视功能检查-眼位
    EC_eyesAdjust: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视功能检查-调节
    EC_eyesFusional: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视功能检查-融像
    EC_VFConclusion: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-视功能检查-结论
    EC_solution: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-检查结论-解决方案
    EC_reexaminationTime: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-检查结论-复查时间
    EC_prescriptionData: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-检查结论-方案数据
    EC_doctorSign: {
        ENTER: 0,
        EXIT: 0
    },//眼部检查-检查结论-签字
    EC_ophtha_IP: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-眼压
    EC_ophtha_FC: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-眼底相机
    EC_ophtha_SL: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-裂隙灯
    EC_ophtha_CTM: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-角膜地形图
    EC_ophtha_OM: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-眼生物测量
    EC_ophtha_SM: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-角膜内皮
    EC_ophtha_TFE: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-泪膜检查
    EC_ophtha_SYN: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-同视机
    EC_ophtha_VF: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-视野
    EC_ophtha_OCT: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-OCT
    EC_ophtha_CS: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-对比敏感度
    EC_ophtha_WI: {
        ENTER: 0,
        EXIT: 0
    },//眼科检查-像差分析仪


    display_material: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-产品演示-材料
    display_design: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-产品演示-设计
    display_film: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-产品演示-膜层
    display_scene: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-产品演示-场景
    display_outdoor: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-产品演示-户外
    display_surgery: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-方案演示-屈光手术
    display_contact: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-方案演示-角膜接触镜
    display_frameGlasses: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-方案演示-框架镜
    display_eyesTraining: {
        ENTER: 0,
        EXIT: 0
    },//方案演示-方案演示-视觉训练
    display_healthManagement: {
        ENTER: 0,
        EXIT: 0
    },//案演示-方案演示-健康管理

    product_detail: {
        ENTER: 0,
        EXIT: 0
    },//框架镜片-产品详情
    product_addToCart: {
        ENTER: 0,
        EXIT: 0
    },//框架镜片-加入选择
    product_addToCart_frame: {
        ENTER: 0,
        EXIT: 0
    },//框架镜框-加入选择
    mnsd: {
        ENTER: 0,
        EXIT: 0
    },//框架镜框-智慧视典模拟试戴

    contact_inquiry: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-问诊
    contact_relativeCheck: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-相关检查-眼科检查
    contact_relativeConclution: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-相关检查-结论
    contact_TE_optomtry: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-试戴评估-屈光度
    contact_TE_trailSlice: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-试戴评估-试戴片数据
    contact_TE_sliceOptometry: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-试戴评估-片上验光
    contact_TE_dynamic: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-试戴评估-动态评估
    contact_TE_static: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜-试戴评估-静态评估
    contact_product: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜订单-产品
    contact_prescriptionData: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜订单-方案数据
    contact_params: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜订单-参数
    contact_date: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜订单-日期
    contact_glassEvaluation: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜复查-镜片评估
    contact_optometryEvaluation: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜复查-屈光评估
    contact_trailEvaluation: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜复查-戴镜评估
    contact_dealingSuggestion: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜复查-处理建议
    contact_protocal: {
        ENTER: 0,
        EXIT: 0
    },//角膜接触镜患者沟通协议

    '3D_front': {
        ENTER: 0,
        EXIT: 0
    },//定制参数建模Pad端-正面测量过程详解
    "3D_side": {
        ENTER: 0,
        EXIT: 0
    },//定制参数建模Pad端-侧面测量过程详解
    referral: {
        ENTER: 0,
        EXIT: 0
    },//眼病转诊 转诊介绍 专家预约
    trade_memberManagement: {
        ENTER: 0,
        EXIT: 0
    },//结算中心-积分管理会员管理/优惠劵推广
    trade_bills: {
        ENTER: 0,
        EXIT: 0
    },//结算中心-ERP开单
    trade_payment: {
        ENTER: 0,
        EXIT: 0
    },//结算中心-支付我的支付方式
    eyesHealthReport: {
        ENTER: 0,
        EXIT: 0
    },//报告详解打印/邮件/手机/结案
    zhsddl: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-顾客登录注册扫描登陆
    jjpgbg: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-旧镜参数测量输出评估报告
    zm: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-定制参数建模正面测量过程详解
    cm: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-定制参数建模侧面测量过程详解
    ar: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-AR体验镜框试戴体验/保存手机分享
    Amsler: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-眼底自测Amsler表自测眼底
    ai: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-人工智能处方引导顾客自己体验
    sjbg: {
        ENTER: 0,
        EXIT: 0
    },//智慧视典-健康报告手机查阅
    cy: {
        ENTER: 0,
        EXIT: 0
    },//报告-设置顾客可以查阅
}


function generateGUID() {
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    return guid;
}


//url把 key转换成URL
function getUrl(key) {
    return store.state.bases.urlMap.get(key)
}

//把JSON字符串转换成***的字符串
function conversionJson(json) {
    if (!json) {
        return ''
    }
    let str = JSON.stringify(json);
    str = str.replace(/"/g, '***')
    return str
}

//把JSON字符串的***转换成"的字符串
function decoding(json){
    if (!json) {
        return ''
    }
    let str = json
    str = str.replace(/\*\*\*/g, '"')
    str = JSON.parse(str)
    return str
}

//简单的节流函数
function throttle(fn, delay) {
    let timer;
    return function () {
        let _this = this;
        let args = arguments;
        if (timer) {
            return;
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
            timer = null;
        }, delay)
    }
}


//简单的防抖函数
function debounce(fn,delay){
    let timer = null //借助闭包
    return function() {
        if(timer){
            clearTimeout(timer) //进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
            timer = setTimeout(fn,delay)
        }else{
            timer = setTimeout(fn,delay) // 进入该分支说明当前并没有在计时，那么就开始一个计时
        }
    }
}

// 获取未来几天或者过去几天的时间
function getTime(day) {
    let today = new Date();
    let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(lastOrNextDate);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    return year + "-" + month + "-" + date;
}

//获取当前时间时分秒
function getDqTime() {
    let date = new Date()
    let h = date.getHours().toString(),
        f = date.getMinutes(),
        m = date.getSeconds()
    if (h.length < 2) {h = '0'+h}
    if (f.length < 2) {f = '0'+f}
    if (m.length < 2) {m = '0'+m}

    return `${h}:${f}:${m}`
}

// 获取未来或者过去的月日时间
function getYR(day) {
    let today = new Date();
    let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(lastOrNextDate);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    return month + "-" + date;
}


//获取未来今天的星期几
function getWeek(day) {

    if (typeof day !== 'number') {
        return false
    }

    let today = new Date(),
        arr = ['周一', '周二','周三','周四','周五','周六','周日']

    let week = today.getDay()
    let item = week
    if (week + day <= 7) {
        item = week + day
    }else {
        item = (week+day)%7
    }

    return arr[item - 1]
}


// 获取未来几天或者过去几天的时间
function getTimeNo(day) {
    let today = new Date();
    let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(lastOrNextDate);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    // month = month < 10 ? '0' + month : month;
    // date = date < 10 ? '0' + date : date;
    return year + "-" + month + "-" + date;
}

// 根据生日获取年龄周岁格式YYYY/MM/DD
function getYear(birthDate) {
    var today = new Date();
    var birth = new Date(birthDate);
    var age = today.getFullYear() - birth.getFullYear();
    var monthDiff = today.getMonth() - birth.getMonth();
    var dayDiff = today.getDate() - birth.getDate();

    // 如果生日还未过，则年龄减一
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}

//获取URL参数
function getUrlVal(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}


//获取URL参数然后请求地址
let val = ''
function getURL() {
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
    });
    if (getUrlVal('companyid')) val = getUrlVal('companyid')
    axios.post('http://wsbusiness.visumall.com:8088/BusinessConfig.ashx?cmd=GetInterfaceAddress', {
        "token": {
            "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
            "TimeSpan": "1535942069232"
        },
        SearchCondition: {
            BCTypeId: val
        }
    })
        .then(res => {
            if (res.InterfaceAddress) {
                getAllUrl(res.InterfaceAddress, loadingInstance)
            }
        })
}

//获取所有的URL的KEY
function getAllUrl (url, loadingInstance) {
    axios.post(url,
        {
            "token": {
                "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
                "TimeSpan": "1535942069232"
            },
            "Version": "4",
            "Type":"VisumallIOSAPP"
        }
    )
        .then(res => {
            if (res && res.GetListResult.length > 0) {
                store.commit('bases/UpUrlArr', res.GetListResult)
                let item = new Map()
                for (let i = 0; i < res.GetListResult.length; i++) {
                    item.set(res.GetListResult[i].IAKey, res.GetListResult[i].IAValue)
                }
                store.commit('bases/UpUrlMap', item)
                loadingInstance.close();
            }else {
                MessageBox('数据加载失败，请稍后再试！', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    center: true})
            }
        })
        .catch(err => {
            MessageBox('数据加载失败，请稍后再试！', '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                center: true})
            loadingInstance.close();
        })
}


//获取随机字符串
function randomString(e) {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}


//初始化提交时间系统
function initTime() {
    store.commit('Time/initTimeObj', TimeInit)
}

//清楚所有关于用户数据
function clCsData() {
    store.commit('isbc/upall', '')
    store.commit('physicianVisits/upAllData', "")
    store.commit('eyeTest/upAllData', "")
    store.commit('expertClinic/upAllData', "")
    store.commit('corneaContact/upAllData', "")
    store.commit('physicianVisits/upIsqm', true)
    store.commit('physicianVisits/upissgqm', true)
    initTime()
}


function GetCookieVal(offset) {
    let endstr = document.cookie.indexOf(';', offset)
    if (endstr == -1) endstr = document.cookie.length
    return unescape(document.cookie.substring(offset, endstr))
}

function GetCookie(name) {
    return window.localStorage.getItem(name)
}

function SetCookie(name, value) {
    let str = typeof value === 'object'? JSON.stringify(value): value
    window.localStorage.setItem(name, str)
}


function isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
}

function add0(str) {
    if (str === '') return ""
    let strs = (str).toString()
    if (isNaN(str)) {
        return ''
    }
    let arr = strs.split('.')
    if (arr.length < 2) {
        return strs+'.00'
    }
    if (arr.length == 2) {
        if (arr[1].length >= 2) {
            return strs
        }else if(arr[1].length == 1){
            return strs+0
        }
    }
}

export default {
    getUrl,
    conversionJson,
    decoding,
    throttle,
    getTime,
    getYear,
    getUrlVal,
    getURL,
    getTimeNo,
    randomString,
    clCsData,
    SetCookie,
    GetCookie,
    initTime,
    debounce,
    isMobile,
    add0,
    getYR,
    getWeek,
    getDqTime,
    generateGUID
}
