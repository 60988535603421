import da from "element-ui/src/locale/lang/da";

let state = {
   timeObj: {
       customerLogin_registeration: {
           ENTER: 0,
           EXIT: 0
       },//"关注公众号/手机绑定/注册个信息/家庭账号"
       customerLogin_normal: {
           ENTER: 0,
           EXIT: 0
       },//姓名/电话模糊登录/扫码登录
       checkCustomerBills: {
           ENTER: 0,
           EXIT: 0
       },//查阅顾客历史消费
       checkCustomerOppointment: {
           ENTER: 0,
           EXIT: 0
       },//查询顾客预约详情
       //wc

       inquiry_customerSay: {
           ENTER: 0,
           EXIT: 0
       },//问诊-顾客主观述求
       inquiry_customerGenderAndAge: {
           ENTER: 0,
           EXIT: 0
       },//问诊-性别年龄信息
       inquiry_specialNeeds: {
           ENTER: 0,
           EXIT: 0
       },//问诊-顾客特殊需求
       inquiry_mainScenes: {
           ENTER: 0,
           EXIT: 0
       },//问诊-用眼场景
       inquiry_distance: {
           ENTER: 0,
           EXIT: 0
       },//问诊-用眼距离
       inquiry_duration: {
           ENTER: 0,
           EXIT: 0
       },//问诊-用眼时长
       inquiry_diseasesHistory: {
           ENTER: 0,
           EXIT: 0
       },//问诊-眼健康病史
       //wc


       EC_eyesightCheck: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视力检查-视力检查 1
       EC_oldGlassesCheck: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视力检查-旧镜情况 1
       EC_oldGlassesAnalyze: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视力检查-旧镜配戴分析
       EC_baseCheck: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-眼部检查-基础检查 1
       EC_fundusCheck: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-眼部检查-眼底检查 1
       EC_computerOptometry: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-屈光检查-电脑验光 1
       EC_subjectiveOptometry: {
           ENTER: 0,
           EXIT: 0
       },//"眼部检查-屈光检查-主观验光 1
       EC_sliceOptometry: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-屈光检查-插片验光/检影验光 1
       EC_eyesPosition: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视功能检查-眼位 1
       EC_eyesAdjust: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视功能检查-调节 1
       EC_eyesFusional: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视功能检查-融像 1
       EC_VFConclusion: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-视功能检查-结论 1
       EC_solution: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-检查结论-解决方案
       EC_reexaminationTime: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-检查结论-复查时间
       EC_prescriptionData: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-检查结论-方案数据
       EC_doctorSign: {
           ENTER: 0,
           EXIT: 0
       },//眼部检查-检查结论-签字
       EC_ophtha_IP: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-眼压 1
       EC_ophtha_FC: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-眼底相机 1
       EC_ophtha_SL: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-裂隙灯 1
       EC_ophtha_CTM: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-角膜地形图 1
       EC_ophtha_OM: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-眼生物测量 1
       EC_ophtha_SM: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-角膜内皮 1
       EC_ophtha_TFE: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-泪膜检查 1
       EC_ophtha_SYN: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-同视机 1
       EC_ophtha_VF: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-视野 1
       EC_ophtha_OCT: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-OCT
       EC_ophtha_CS: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-对比敏感度
       EC_ophtha_WI: {
           ENTER: 0,
           EXIT: 0
       },//眼科检查-像差分析仪 1


       display_material: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-产品演示-材料
       display_design: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-产品演示-设计
       display_film: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-产品演示-膜层
       display_scene: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-产品演示-场景
       //产品
       display_outdoor: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-产品演示-户外
       display_surgery: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-方案演示-屈光手术
       display_contact: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-方案演示-角膜接触镜
       display_frameGlasses: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-方案演示-框架镜
       display_eyesTraining: {
           ENTER: 0,
           EXIT: 0
       },//方案演示-方案演示-视觉训练
       display_healthManagement: {
           ENTER: 0,
           EXIT: 0
       },//案演示-方案演示-健康管理
       // 处置方案

       product_detail: {
           ENTER: 0,
           EXIT: 0
       },//框架镜片-产品详情 1
       product_addToCart: {
           ENTER: 0,
           EXIT: 0
       },//框架镜片-加入选择 1
       product_addToCart_frame: {
           ENTER: 0,
           EXIT: 0
       },//框架镜框-加入选择 1
       mnsd: {
           ENTER: 0,
           EXIT: 0
       },//框架镜框-智慧视典模拟试戴


       contact_inquiry: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-问诊 1
       contact_relativeCheck: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-相关检查-眼科检查 1
       contact_relativeConclution: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-相关检查-结论 1
       contact_TE_optomtry: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-试戴评估-屈光度1
       contact_TE_trailSlice: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-试戴评估-试戴片数据 1
       contact_TE_sliceOptometry: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-试戴评估-片上验光 1
       contact_TE_dynamic: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-试戴评估-动态评估 1
       contact_TE_static: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜-试戴评估-静态评估 1
       contact_product: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜订单-产品1
       contact_prescriptionData: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜订单-方案数据 1
       contact_params: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜订单-参数 1
       contact_date: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜订单-日期 1
       contact_glassEvaluation: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜复查-镜片评估 1
       contact_optometryEvaluation: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜复查-屈光评估 1
       contact_trailEvaluation: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜复查-戴镜评估 1
       contact_dealingSuggestion: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜复查-处理建议 1
       contact_protocal: {
           ENTER: 0,
           EXIT: 0
       },//角膜接触镜患者沟通协议 1

       '3D_front': {
           ENTER: 0,
           EXIT: 0
       },//定制参数建模Pad端-正面测量过程详解
       "3D_side": {
           ENTER: 0,
           EXIT: 0
       },//定制参数建模Pad端-侧面测量过程详解
       referral: {
           ENTER: 0,
           EXIT: 0
       },//眼病转诊 转诊介绍 专家预约
       trade_memberManagement: {
           ENTER: 0,
           EXIT: 0
       },//结算中心-积分管理会员管理/优惠劵推广
       trade_bills: {
           ENTER: 0,
           EXIT: 0
       },//结算中心-ERP开单 1
       trade_payment: {
           ENTER: 0,
           EXIT: 0
       },//结算中心-支付我的支付方式 1
       eyesHealthReport: {
           ENTER: 0,
           EXIT: 0
       },//报告详解打印/邮件/手机/结案 1
       zhsddl: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-顾客登录注册扫描登陆
       jjpgbg: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-旧镜参数测量输出评估报告
       zm: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-定制参数建模正面测量过程详解
       cm: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-定制参数建模侧面测量过程详解
       ar: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-AR体验镜框试戴体验/保存手机分享
       Amsler: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-眼底自测Amsler表自测眼底
       ai: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-人工智能处方引导顾客自己体验
       sjbg: {
           ENTER: 0,
           EXIT: 0
       },//智慧视典-健康报告手机查阅
       cy: {
           ENTER: 0,
           EXIT: 0
       },//报告-设置顾客可以查阅
       EC_Santong: {
           ENTER: 0,
           EXIT: 0
       },//泪分
       EC_SLP: {
           ENTER: 0,
           EXIT: 0
       },//泪目冲洗
       EC_BloodDraw: {
           ENTER: 0,
           EXIT: 0
       },//采血
   }

}

let getters = {

}

let actions = {

}

let mutations = {
   upTimeObj(state, obj) {
       obj =  JSON.parse(JSON.stringify(obj))
       state.timeObj[obj.keys] = obj.val
   },
    upTimeExit(state, obj) {
        state.timeObj[obj.keys].EXIT = obj.outTime
    },
    initTimeObj(state, data) {
        state.timeObj = JSON.parse(JSON.stringify(data))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
