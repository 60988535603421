export default [
    {
        path: '/corneaContactHome',
        component: () => import('@/views/corneaContact/corneaContact'),
        children: [
            {
                path: '/',
                component: () => import('@/views/corneaContact/corneaContactHome')
            },
            {
                path: '/corneaContact/introduce',
                name: 'introduce',
                component: () => import('@/views/corneaContact/introduce')
            },
            {
                path: '/corneaContact/productDemonstration',
                name: 'productDemonstration',
                component: () => import('@/views/corneaContact/productDemonstration')
            },
            {
                path: '/corneaContact/communication',
                name: 'communication',
                component: () => import('@/views/corneaContact/communication')
            },
            {
                path: '/corneaContact/tryAssessmentHome',
                name: 'tryAssessmentHome',
                component: () => import('@/views/corneaContact/tryAssessmentHome')
            },
            {
                path: '/corneaContact/relatedTest',
                name: 'relatedTest',
                component: () => import('@/views/corneaContact/relatedTest')
            },
            {
                path: '/corneaContact/askDiagnosis',
                name: 'askDiagnosis',
                component: () => import('@/views/corneaContact/askDiagnosis')
            },
            {
                path: '/corneaContact/corneaOrder',
                name: 'corneaOrder',
                component: () => import('@/views/corneaContact/corneaOrder')
            },
            {
                path: '/corneaContact/corneaReview',
                name: 'corneaReview',
                component: () => import('@/views/corneaContact/corneaReview')
            },
            {
                path: '/corneaContact/Iframe',
                name: 'Iframe',
                component: () => import('@/components/CommonComponents/Iframe')
            },
            {
                path: '/corneaContact/inquiry',
                name: 'inquiry',
                component: () => import('../components/jqDIY/inquiry')
            },
            {
                path: '/corneaContact/tryJQ',
                name: 'inquiry',
                component: () => import('../components/jqDIY/trysd/JQtryHome')
            },
            {
                path: '/corneaContact/jqcorneaOrder',
                name: 'JQcorneaOrder',
                component: () => import('../components/jqDIY/JQcorneaOrder')
            },
            {
                path: '/corneaContact/jqcorneaOrder/yanpian',
                name: 'yanpian',
                component: () => import('../components/jqDIY/yanpian')
            },
            {
                path: '/corneaContact/jqcorneaOrder/jiaopi',
                name: 'jiaopi',
                component: () => import('../components/jqDIY/jiaopi')
            },
            {
                path: '/corneaContact/sxjsyzhb',
                name: 'sxjsyzhb',
                component: () => import('../components/jqDIY/sxjsyzhb')
            },
        ]
    }
]
