import da from "element-ui/src/locale/lang/da";
import fa from "element-ui/src/locale/lang/fa";
// 问诊的数据


let state = {
    jzyz: {
        wz: false,
        sljc: false,
        jcjc:false,
        dnyg: false,
        yyjc: false,
        yswjc: false,
        st:false,
        cx: false,
        ldcx: false
    },
    tjk: {
        lxd: false,
        ydjc: false,
        yxyg:false,
        kjsd: false,
        sgnjc: false,
        ydxj: false,
        jmdxt: false,
        yswcl: false,
        jpnmj: false,
        lmjc: false,
        tsj: false,
        sy: false,
        xcfxy: false,
        ubm: false,
        fjjjc: false,
        oct: false,
        objc: false,
        gyfx: false
    },
    zjzs: {
        Recomm1: false,
        Recomm2: false,
        Recomm3: false,
        S_OP_MR: false
    }
}

let getters = {

}

let actions = {

}

let mutations = {
  upjzyz(state, data) {
      let obj = JSON.parse(JSON.stringify(state.jzyz))
      obj[data.keys] = data.val
      state.jzyz = obj
      obj = null
  },
  uptjk(state, data) {
      let obj = JSON.parse(JSON.stringify(state.tjk))
      obj[data.keys] = data.val
      state.tjk = obj
      obj = null
  },
    upall(state, data) {
      state.jzyz = {
          wz: false,
          sljc: false,
          jcjc:false,
          dnyg: false,
          yyjc: false,
          yswjc: false,
          st:false,
          cx: false,
          ldcx: false
      }
      state.tjk = {
          lxd: false,
          ydjc: false,
          yxyg:false,
          kjsd: false,
          sgnjc: false,
          ydxj: false,
          jmdxt: false,
          yswcl: false,
          jpnmj: false,
          lmjc: false,
          tsj: false,
          sy: false,
          xcfxy: false,
          ubm: false,
          fjjjc: false,
          oct: false,
          objc: false,
          gyfx: false
      }
    },


    upZjzs(state, data) {
        let obj = JSON.parse(JSON.stringify(state.zjzs))
        obj[data.key] = data.val
        state.zjzs = obj
        obj = null
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
