import da from "element-ui/src/locale/lang/da";

let state = {
    urlArr: '',
    urlMap: '',
    shop: '',
    bottomIdx: 0,
    orid: '', //订单ID
    qx: '',
}

let getters = {

}

let actions = {

}

let mutations = {
    UpUrlArr(state, data) {
        state.urlArr = data
    },
    UpUrlMap(state, data) {
        state.urlMap = data
    },
    UpShop(state, data) {
        state.shop = data
    },
    upBottomIdx(state, idx) {
        state.bottomIdx = idx
    },
    upOrid(state, data) {
        state.orid = data
    },
    upqx(state, data) {
        state.qx = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
