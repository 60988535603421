import da from "element-ui/src/locale/lang/da";

let state = {
    trainingDiagnosis: ''
}

let getters = {

}

let actions = {

}

let mutations = {
    upTrainingDiagnosis(state, data) {
        state.trainingDiagnosis = JSON.parse(JSON.stringify(data))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}