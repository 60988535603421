//问诊的所有接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import da from "element-ui/src/locale/lang/da";
import {MessageBox} from "element-ui";

// 保存成人问诊updateInquiry
function physicianVisitsSave () {
    let datas = JSON.parse(JSON.stringify(store.state.physicianVisits.datas))
    for (let key in store.state.physicianVisits.aldultZS) {
        datas[key] = store.state.physicianVisits.aldultZS[key]
    }
    for (let key in store.state.physicianVisits.aldultJKMS) {
        datas[key] = store.state.physicianVisits.aldultJKMS[key]
    }
    for (let key in store.state.physicianVisits.aldultZYYY) {
        datas[key] = store.state.physicianVisits.aldultZYYY[key]
    }
    for (let key in store.state.physicianVisits.aldultYYCJ) {
        datas[key] = store.state.physicianVisits.aldultYYCJ[key]
    }
    if (!datas.InquiryChooseId) {
        datas.InquiryChooseId = store.state.physicianVisits.xzConsulting
    }
    // console.log(datas)
   return axios.post(publicFn.getUrl('Inquiry_Update'), {
       "token": {
           "TimeSpan": 1566827733,
           "sToken": "768DCAFFF869CD61372790C2738012EC"
       },
       o: datas
   })
}

// 获取成人单个咨询的问诊getContentWithChooseID
function getChoose(id) {
    return axios.post(publicFn.getUrl('getContentWithChooseID'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            ChooseId: id
        },
        ReturnInfo: {
            Inquiry: 1
        }
    })
}

// 获取青少年单个咨询问诊PS_all_get
function getTeenagerChoose(id) {
    return axios.post(publicFn.getUrl('PS_all_get'), {
        "token": {
            "TimeSpan": 1584328935,
            "sToken": "CE83037A4CB13EBF5EE18246885E7719"
        },
        SearchCondition: {
            ChooseId: id,
            S_OP_Type: 'InquiryChildren'
        }
    })
}

//保存青少年咨询问诊S_ALL_Normal_Save
function saveTeenager() {
    let datas = JSON.parse(JSON.stringify(store.state.physicianVisits.teenager)),
        zs = store.state.physicianVisits.teenagerZs,
        ybzz = store.state.physicianVisits.teenagerYbzz,
        yyxg = store.state.physicianVisits.teenagerYyxg,
        jkms = store.state.physicianVisits.teenagerJkms
    if (!datas.S_OP_ChooseId) {
        datas.S_OP_ChooseId = store.state.physicianVisits.xzConsulting
        datas.S_OP_CustomerId = store.state.users.CsUser.CustomerId
        datas.S_OP_Json.S_OP_ICH_ChooseId = store.state.physicianVisits.xzConsulting
        datas.S_OP_Json.S_OP_ID  = '00000000-0000-0000-0000-000000000000'
        datas.S_OP_ID = '00000000-0000-0000-0000-000000000000'
        if (store.state.users.employees) datas.S_OP_ExpertId = store.state.users.employees.ExpertId
    }
    for (let key in zs) {
        datas.S_OP_Json[key] = zs[key]
    }

    for (let key in ybzz) {
        datas.S_OP_Json[key] = ybzz[key]
    }

    for (let key in yyxg) {
        datas.S_OP_Json[key] = yyxg[key]
    }

    for (let key in jkms) {
        datas.S_OP_Json[key] = jkms[key]
    }

    if (zs.S_OP_ICH_height && zs.S_OP_ICH_weight) {
        datas.S_OP_Json.S_OP_ICH_BMI = (zs.S_OP_ICH_weight/Math.pow(zs.S_OP_ICH_height/100, 2)).toFixed(2)
        datas.S_OP_Json.S_OP_ICH_BMI = Number(datas.S_OP_Json.S_OP_ICH_BMI).toString()
    }else {
        datas.S_OP_Json.S_OP_ICH_BMI = Number(0).toString()
    }
    datas.S_OP_Json = publicFn.conversionJson(datas.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        o: datas
    })
}

//新建初诊customer_addNewChoose
function addNewChoose(id='00000000-0000-0000-0000-000000000000', opid='', hwid = '') {
    let exid = '00000000-0000-0000-0000-000000000000'
    if (store.state.users.employees) exid = store.state.users.employees.ExpertId
    // console.log({
    //     ChooseCustomerId: store.state.users.CsUser.CustomerId,
    //     ChooseShopId: store.state.bases.shop.ShopId,
    //     ChooseUserId: store.state.users.user.UserId,
    //     ChooseCompanyId: store.state.users.user.Company.CompanyId,
    //     ChooseExpertId: exid,
    //     ChooseParentId: id
    // })
    return axios.post(publicFn.getUrl('customer_addNewChoose'),{
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        o: {
            ChooseCustomerId: store.state.users.CsUser.CustomerId,
            ChooseShopId: store.state.bases.shop.ShopId,
            ChooseUserId: store.state.users.user.UserId,
            ChooseCompanyId: store.state.users.user.Company.CompanyId,
            ChooseExpertId: exid,
            ChooseParentId: id||'00000000-0000-0000-0000-000000000000',
            "ChooseHealthCheckId": hwid //筛查ID
        },
        OppointmentId: opid
    })
}

// 获取医生排班的列表Schedule_GetList
function getExpertScheduleList(id, time) {
    if (!time) {
        let date = new Date(), n='', y='', r=''
        n = date.getFullYear()
        y = date.getMonth() + 1
        r = date.getDate()
        time = `${n}-${y}-${r}`
    }
    return axios.post(publicFn.getUrl('Schedule_GetList'), {
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        SearchCondition: {
            IndexMin: 0,
            IndexMax: 0,
            ScheduleCompanyId: store.state.users.user.Company.CompanyId,
            ScheduleShopId: store.state.bases.shop.ShopId,
            ScheduleExpertId: id,
            ScheduleDateStart: time,
            ScheduleDateEnd: time
        },
        ReturnInfo: {
            Expert: {},
            Dept: {}
        }
    })
}
//体检预约的接口
function addYy(exid, yyid, time, ChooseId='') {
    return axios.post(publicFn.getUrl('insertOppointment'), {
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        o: {
            OppointmentChooseId: ChooseId,
            OppointmentShopId: store.state.bases.shop.ShopId,
            OppointmentExpertId: exid,
            OppointmentCustomerId: store.state.users.CsUser.CustomerId,
            OppointmentScheduleId: yyid,
            OppointmentTime: time,
            OppointmentType: 'VPOppointment'
        }
    })
}

function addKfjl(type, id) {
    return axios.post(publicFn.getUrl('CustomerCompanyRelation_Insert'), {
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        o: {
            "CCRCompanyId": store.state.users.user.Company.CompanyId,
            "CCRCustomerId": store.state.users.CsUser.CustomerId,
            "CCRType":type,
            "CCRValue":id
        },
        "ReturnInfo":{}
    })
}

function getKfjl() {
    return axios.post(publicFn.getUrl('CustomerCompanyRelation_GetList'), {
        "token": {
            "TimeSpan": 1559227408,
            "sToken": "D35C30181D26439F92471835A4A98624"
        },
        SearchCondition: {
            "CCRCompanyId": store.state.users.user.Company.CompanyId,
            "CCRCustomerId": store.state.users.CsUser.CustomerId,
            "CCRType":"CustomerServiceExpert1,CustomerServiceExpert2",
        }
    })
}

function savTears() {
    let data = JSON.parse(JSON.stringify(store.state.physicianVisits.mydriasis))
    data.S_OP_Json = publicFn.conversionJson(data.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: data
    })
        .then(res => {
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'st'
                })
            }else {
                MessageBox('散瞳保存失败，请稍后再试', '提示')
            }
        })
}


function savLacrimalirrigation() {
    let data = JSON.parse(JSON.stringify(store.state.physicianVisits.lacrimalirrigation))
    data.S_OP_Json = publicFn.conversionJson(data.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: data
    })
        .then(res => {
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'ldcx'
                })
                MessageBox('泪道冲洗保存成功', '提示')
            }else {
                MessageBox('泪道冲洗保存失败，请稍后再试', '提示')
            }
        })
}

function savbloodCollection() {
    let data = JSON.parse(JSON.stringify(store.state.physicianVisits.bloodcollection))
    // console.log(store.state.physicianVisits.bloodcollection)
    data.S_OP_Json = publicFn.conversionJson(data.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: data
    })
        .then(res => {
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'cx'
                })
                MessageBox('采血保存成功', '提示')
            }else {
                MessageBox('采血保存失败，请稍后再试', '提示')
            }
        })
}


//获取干眼分析的接口PS_all_Normal_getList
function getGyfx() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_MG',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}




export default {
    getChoose,
    physicianVisitsSave,
    getTeenagerChoose,
    saveTeenager,
    addNewChoose,
    getExpertScheduleList,
    addYy,
    addKfjl,
    getKfjl,
    savTears,
    savLacrimalirrigation,
    savbloodCollection,
    getGyfx
}
