import da from "element-ui/src/locale/lang/da";

let state = {
    user: '',
    CsUser: '',
    CsAge: '',
    employees: '',
    CsUserItem: '',
    logged: false,
    jurisdiction: "",
    wcJurisdiction: '',
    csObj: '',
    usererp: {
        name: '',
        pwd: ''
    }
}

let getters = {
    getuser(state) {
      return state.user
    }
}

let actions = {

}

let mutations = {
    upusererp(state, data) {
        state.usererp = JSON.parse(JSON.stringify(data))
    },
    UpUser(state, data) {
        state.user = data
    },
    UpCsUser(state, data) {
        state.CsUser = data
    },
    upCsAge(state, data) {
        state.CsAge = data
    },
    upEmployees(state, data) {
        state.employees = data
    },
    upCsUserItem(state, data) {
        state.CsUserItem = data
    },
    upLogged(state, data) {
        state.logged = data
    },
    upJurisdiction(state, data) {
        state.jurisdiction = data
    },
    upWcJurisdiction(state, data) {
        state.wcJurisdiction = data
    },
    upCsObj(state, data) {
        state.csObj = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
