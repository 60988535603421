import da from "element-ui/src/locale/lang/da";
// 问诊的数据


let state = {
    crData: {},
    datas: {
      InquiryCardiovascularDes: '',
      InquiryOccupation: '',
      InquiryEyeOperation: '',
      InquiryHomeSickDes: '',
      ProductSource: '',
      InquiryIntention: [],
      InquiryFrequency: '',
      InquiryEyeOperationDes: '',
      InquiryHBGHBPDes: '',
      InquiryScene: '',
      InquiryDriveTime: '',
      InquiryDiabetes: '',
      InquiryWeight: '',
      InquiryId: '',
      InquiryLeftVSC: '',
      InquiryNeeds: '',
      InquiryHighMyopia: '',
      InquiryGender: '',
      InquiryDiabetesDes: '',
      InquiryChooseId: '',
      InquiryOther: '',
      InquiryHeight: '',
      InquiryHighMyopiaDes: '',
      InquiryRightVSC: '',
      InquiryCardiovascular: '',
      InquiryExamSituation: '',
      InquiryAcitivity: '',
      InquiryHBGHBP: '',
      InquiryNightDriveProblem: '',
      InquiryAge: '',
      InquiryBMI: '',
      InquiryHomeSick: ''
    }, //成人数据
    xzConsulting: '', //选择的咨询ID
    ChooseIdAddTime: '',
    jump: 0, //是否跳转到问诊
    cancellation: false,
    isqm: true,//咨询是否已经签名
    issgqm: true,//咨询视光师是否已经签名
    idxs: 0, //现在是哪个项目

    teenager: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "",
        S_OP_HealthCheckId: "",
        S_OP_ID: "",
        S_OP_Json: {
            S_OP_ICH_BMI: "",
            S_OP_ICH_ChooseId: "",
            S_OP_ICH_DR1: "",
            S_OP_ICH_DR2: "",
            S_OP_ICH_DR3: "",
            S_OP_ICH_DR4: "",
            S_OP_ICH_DR5: "",
            S_OP_ICH_DR6: "",
            S_OP_ICH_DR7: "",
            S_OP_ICH_DR8: "",
            S_OP_ICH_DR9: "",
            S_OP_ICH_DR10: "",
            S_OP_ICH_DR11: "",
            S_OP_ICH_DR12: "",
            S_OP_ICH_FPArray1: '1,0',
            S_OP_ICH_FPArray2: "1,0",
            S_OP_ICH_FPArray3: "1,0",
            S_OP_ICH_FPArray4: "1,0",
            S_OP_ICH_FPArray5: "1,0",
            S_OP_ICH_FPArray6: "1,0",
            S_OP_ICH_FPArray7: "1,0",
            S_OP_ICH_FPArray8: "1,0",
            S_OP_ICH_FPDes1: "",
            S_OP_ICH_FPDes2: "",
            S_OP_ICH_FPDes3: "",
            S_OP_ICH_FPDes4: "",
            S_OP_ICH_FPDes5: "",
            S_OP_ICH_FPDes6: "",
            S_OP_ICH_FPDes7: "",
            S_OP_ICH_FPDes8: "",
            S_OP_ICH_FPremark: "",
            S_OP_ICH_HistoryArray: "",
            S_OP_ICH_SeH1: "",
            S_OP_ICH_SeH2: "",
            S_OP_ICH_SeH3: "",
            S_OP_ICH_SeH4: "",
            S_OP_ICH_SeH5: "",
            S_OP_ICH_SeH6: "",
            S_OP_ICH_SeQ1: "",
            S_OP_ICH_SeQ2: "",
            S_OP_ICH_SeQ3: "",
            S_OP_ICH_SeQ4: "",
            S_OP_ICH_SeQ5: "",
            S_OP_ICH_SeQ6: "",
            S_OP_ICH_WE1: "",
            S_OP_ICH_WE2: "",
            S_OP_ICH_WE3: "",
            S_OP_ICH_WE4: "",
            S_OP_ICH_WE5: "",
            S_OP_ICH_WE6: "",
            S_OP_ICH_WE7: "",
            S_OP_ICH_WE8: "",
            S_OP_ICH_WE9: "",
            S_OP_ICH_WE10: "",
            S_OP_ICH_WE11: "",
            S_OP_ICH_WE12: "",
            S_OP_ICH_electronicTime: "",
            S_OP_ICH_eyesEnvirArray: "",
            S_OP_ICH_height: "",
            S_OP_ICH_homeworkArray: "",
            S_OP_ICH_intention: "",
            S_OP_ICH_needs: "",
            S_OP_ICH_otherReason: "",
            S_OP_ICH_outdoorTime: "",
            S_OP_ICH_readingTime: "",
            S_OP_ICH_remark1: "",
            S_OP_ICH_remark2: "",
            S_OP_ICH_remark3: "",
            S_OP_ICH_sicknessDuration1: "",
            S_OP_ICH_sicknessDuration2: "",
            S_OP_ICH_sicknessDuration3: "",
            S_OP_ICH_sicknessDuration4: "",
            S_OP_ICH_sicknessDuration5: "",
            S_OP_ICH_sicknessDuration6: "",
            S_OP_ICH_sleepTime: "",
            S_OP_ICH_symptomArray: ",",
            S_OP_ICH_weight: "",
            S_OP_ICH_writtingPostArray: ""
        },
        S_OP_Model: "InquiryChildren200323",
        S_OP_Time: "",
        S_OP_Type: "InquiryChildren",
        S_OP_XML: ""
    }, //青少年数据

    isgetData: 0, //问诊是否要获取数据,


    teenagerZs: {
        S_OP_ICH_remark1: '',
        S_OP_ICH_WE1: '',
        S_OP_ICH_DR1: '',
        S_OP_ICH_WE2: '',
        S_OP_ICH_DR2: '',
        S_OP_ICH_WE3: '',
        S_OP_ICH_DR3: '',
        S_OP_ICH_WE4: '',
        S_OP_ICH_DR4: '',
        S_OP_ICH_WE5: '',
        S_OP_ICH_DR5: '',
        S_OP_ICH_WE6: '',
        S_OP_ICH_DR6: '',
        S_OP_ICH_WE7: '',
        S_OP_ICH_DR7: '',
        S_OP_ICH_WE8: '',
        S_OP_ICH_DR8: '',
        S_OP_ICH_WE9: '',
        S_OP_ICH_DR9: '',
        S_OP_ICH_WE10: '',
        S_OP_ICH_DR10: '',
        S_OP_ICH_WE11: '',
        S_OP_ICH_DR11: '',
        S_OP_ICH_WE12: '',
        S_OP_ICH_DR12: '',
        S_OP_ICH_intention: '0,0,0,0,0,0,0,0,0,0,0,0',
        S_OP_ICH_height: '',
        S_OP_ICH_weight: '',
        S_OP_ICH_needs: '',
        S_OP_ICH_otherReason: ''
    },   //青少年问诊主诉数据
    teenagerYbzz: {
        S_OP_ICH_SeQ1: '',
        S_OP_ICH_SeH1: '',
        S_OP_ICH_SeQ2: '',
        S_OP_ICH_SeH2: '',
        S_OP_ICH_SeQ3: '',
        S_OP_ICH_SeH3: '',
        S_OP_ICH_SeQ4: '',
        S_OP_ICH_SeH4: '',
        S_OP_ICH_SeQ5: '',
        S_OP_ICH_SeH5: '',
        S_OP_ICH_SeQ6: '',
        S_OP_ICH_SeH6: '',
        S_OP_ICH_symptomArray: '0,0,0,0,0,0',
        S_OP_ICH_remark2: ''
    },  //青少年眼部症状
    teenagerYyxg: {
        S_OP_ICH_readingTime: '',
        S_OP_ICH_electronicTime: '',
        S_OP_ICH_sleepTime: '',
        S_OP_ICH_outdoorTime: '',
        S_OP_ICH_remark3: '',
        S_OP_ICH_eyesEnvirArray: '0,0,0,0',
        S_OP_ICH_homeworkArray: '0,0,0',
        S_OP_ICH_writtingPostArray: '0,0,0'
    },  //青少年习惯用眼
    teenagerJkms: {
        S_OP_ICH_FPDes1: '',
        S_OP_ICH_sicknessDuration1: '',
        S_OP_ICH_FPDes2: '',
        S_OP_ICH_sicknessDuration2: '',
        S_OP_ICH_FPDes3: '',
        S_OP_ICH_sicknessDuration3: '',
        S_OP_ICH_FPDes4: '',
        S_OP_ICH_sicknessDuration4: '',
        S_OP_ICH_FPDes5: '',
        S_OP_ICH_sicknessDuration5: '',
        S_OP_ICH_FPDes6: '',
        S_OP_ICH_sicknessDuration6: '',
        S_OP_ICH_FPDes7: '',
        S_OP_ICH_FPDes8: '',
        S_OP_ICH_HistoryArray: '0,0,0,0,0,0',
        S_OP_ICH_FPArray1: '0,0',
        S_OP_ICH_FPArray2: '0,0',
        S_OP_ICH_FPArray3: '0,0',
        S_OP_ICH_FPArray4: '0,0',
        S_OP_ICH_FPArray5: '0,0',
        S_OP_ICH_FPArray6: '0,0',
        S_OP_ICH_FPArray7: '0,0',
        S_OP_ICH_FPArray8: '0,0',
        S_OP_ICH_FPremark: '0,0'
    }, //青少年身体健康描述


    aldultZS: {},
    aldultJKMS: {},
    aldultZYYY: {},
    aldultYYCJ: {},

    mydriasis: {}, //散瞳
    bloodcollection: {},  //采血
    lacrimalirrigation: {},  //泪道


    wz: false,
    sljc: false,
    jcjc:false,
    dnyg: false,
    yyjc: false,
    yswjc: false
}

let getters = {

}

let actions = {

}

let mutations = {
    upDatas(state, data) {
      state.datas = data
    },
    upXzConsulting(state, data) {
        state.isqm = true
        state.xzConsulting = data
    },
    upIsqm(state, data) {
        state.isqm = data
    },
    upissgqm(state, data) {
        state.issgqm = data
    },
    upJump(state, data) {
      state.jump =  state.jump + data
    },
    upCancellation(state, data) {
        state.cancellation = data
    },
    upTeenager(state, data) {
        state.teenager = data
    },
    upTeenagerChooseId(state, data) {
        state.teenager.S_OP_ICH_ChooseId = data
    },
    upIdxs(state, idx) {
        state.idxs = idx
    },
    upCrData(state, data) {
        state.crData =  data
    },
    upIsgetData(state, data) {
        state.isgetData = data
    },



    upmydriasis(state, data) {
        state.mydriasis = JSON.parse(JSON.stringify(data))
    },
    upbloodcollection(state, data) {
        state.bloodcollection = JSON.parse(JSON.stringify(data))
    },
    uplacrimalirrigation(state, data) {
        state.lacrimalirrigation = JSON.parse(JSON.stringify(data))
    },



    upAllData(state, data) {
        state.crData= {}
        state.datas= {
            InquiryCardiovascularDes: '',
                InquiryOccupation: '',
                InquiryEyeOperation: '',
                InquiryHomeSickDes: '',
                ProductSource: '',
                InquiryIntention: [],
                InquiryFrequency: '',
                InquiryEyeOperationDes: '',
                InquiryHBGHBPDes: '',
                InquiryScene: '',
                InquiryDriveTime: '',
                InquiryDiabetes: '',
                InquiryWeight: '',
                InquiryId: '',
                InquiryLeftVSC: '',
                InquiryNeeds: '',
                InquiryHighMyopia: '',
                InquiryGender: '',
                InquiryDiabetesDes: '',
                InquiryChooseId: '',
                InquiryOther: '',
                InquiryHeight: '',
                InquiryHighMyopiaDes: '',
                InquiryRightVSC: '',
                InquiryCardiovascular: '',
                InquiryExamSituation: '',
                InquiryAcitivity: '',
                InquiryHBGHBP: '',
                InquiryNightDriveProblem: '',
                InquiryAge: '',
                InquiryBMI: '',
                InquiryHomeSick: ''
        } //成人数据
        state.xzConsulting= '' //选择的咨询ID
        state.ChooseIdAddTime = ''
        state.jump= 0 //是否跳转到问诊
        state.cancellation= false
        state.idxs= 0 //现在是哪个项目
        state.teenager= {
            S_OP_ChooseId: "",
                S_OP_CustomerId: "",
                S_OP_ExpertId: "",
                S_OP_HealthCheckId: "",
                S_OP_ID: "",
                S_OP_Json: {
                    S_OP_ICH_BMI: "",
                    S_OP_ICH_ChooseId: "",
                    S_OP_ICH_DR1: "",
                    S_OP_ICH_DR2: "",
                    S_OP_ICH_DR3: "",
                    S_OP_ICH_DR4: "",
                    S_OP_ICH_DR5: "",
                    S_OP_ICH_DR6: "",
                    S_OP_ICH_DR7: "",
                    S_OP_ICH_DR8: "",
                    S_OP_ICH_DR9: "",
                    S_OP_ICH_DR10: "",
                    S_OP_ICH_DR11: "",
                    S_OP_ICH_DR12: "",
                    S_OP_ICH_FPArray1: '1,0',
                    S_OP_ICH_FPArray2: "1,0",
                    S_OP_ICH_FPArray3: "1,0",
                    S_OP_ICH_FPArray4: "1,0",
                    S_OP_ICH_FPArray5: "1,0",
                    S_OP_ICH_FPArray6: "1,0",
                    S_OP_ICH_FPArray7: "1,0",
                    S_OP_ICH_FPArray8: "1,0",
                    S_OP_ICH_FPDes1: "",
                    S_OP_ICH_FPDes2: "",
                    S_OP_ICH_FPDes3: "",
                    S_OP_ICH_FPDes4: "",
                    S_OP_ICH_FPDes5: "",
                    S_OP_ICH_FPDes6: "",
                    S_OP_ICH_FPDes7: "",
                    S_OP_ICH_FPDes8: "",
                    S_OP_ICH_FPremark: "",
                    S_OP_ICH_HistoryArray: "",
                    S_OP_ICH_SeH1: "",
                    S_OP_ICH_SeH2: "",
                    S_OP_ICH_SeH3: "",
                    S_OP_ICH_SeH4: "",
                    S_OP_ICH_SeH5: "",
                    S_OP_ICH_SeH6: "",
                    S_OP_ICH_SeQ1: "",
                    S_OP_ICH_SeQ2: "",
                    S_OP_ICH_SeQ3: "",
                    S_OP_ICH_SeQ4: "",
                    S_OP_ICH_SeQ5: "",
                    S_OP_ICH_SeQ6: "",
                    S_OP_ICH_WE1: "",
                    S_OP_ICH_WE2: "",
                    S_OP_ICH_WE3: "",
                    S_OP_ICH_WE4: "",
                    S_OP_ICH_WE5: "",
                    S_OP_ICH_WE6: "",
                    S_OP_ICH_WE7: "",
                    S_OP_ICH_WE8: "",
                    S_OP_ICH_WE9: "",
                    S_OP_ICH_WE10: "",
                    S_OP_ICH_WE11: "",
                    S_OP_ICH_WE12: "",
                    S_OP_ICH_electronicTime: "",
                    S_OP_ICH_eyesEnvirArray: "",
                    S_OP_ICH_height: "",
                    S_OP_ICH_homeworkArray: "",
                    S_OP_ICH_intention: "",
                    S_OP_ICH_needs: "",
                    S_OP_ICH_otherReason: "",
                    S_OP_ICH_outdoorTime: "",
                    S_OP_ICH_readingTime: "",
                    S_OP_ICH_remark1: "",
                    S_OP_ICH_remark2: "",
                    S_OP_ICH_remark3: "",
                    S_OP_ICH_sicknessDuration1: "",
                    S_OP_ICH_sicknessDuration2: "",
                    S_OP_ICH_sicknessDuration3: "",
                    S_OP_ICH_sicknessDuration4: "",
                    S_OP_ICH_sicknessDuration5: "",
                    S_OP_ICH_sicknessDuration6: "",
                    S_OP_ICH_sleepTime: "",
                    S_OP_ICH_symptomArray: ",",
                    S_OP_ICH_weight: "",
                    S_OP_ICH_writtingPostArray: ""
            },
            S_OP_Model: "InquiryChildren200323",
                S_OP_Time: "",
                S_OP_Type: "InquiryChildren",
                S_OP_XML: ""
        } //青少年数据
        state.isgetData= 0 //问诊是否要获取数据

        state.teenagerZs = {
            S_OP_ICH_remark1: '',
                S_OP_ICH_WE1: '',
                S_OP_ICH_DR1: '',
                S_OP_ICH_WE2: '',
                S_OP_ICH_DR2: '',
                S_OP_ICH_WE3: '',
                S_OP_ICH_DR3: '',
                S_OP_ICH_WE4: '',
                S_OP_ICH_DR4: '',
                S_OP_ICH_WE5: '',
                S_OP_ICH_DR5: '',
                S_OP_ICH_WE6: '',
                S_OP_ICH_DR6: '',
                S_OP_ICH_WE7: '',
                S_OP_ICH_DR7: '',
                S_OP_ICH_WE8: '',
                S_OP_ICH_DR8: '',
                S_OP_ICH_WE9: '',
                S_OP_ICH_DR9: '',
                S_OP_ICH_WE10: '',
                S_OP_ICH_DR10: '',
                S_OP_ICH_WE11: '',
                S_OP_ICH_DR11: '',
                S_OP_ICH_WE12: '',
                S_OP_ICH_DR12: '',
                S_OP_ICH_intention: '0,0,0,0,0,0,0,0,0,0,0,0',
                S_OP_ICH_height: '',
                S_OP_ICH_weight: '',
                S_OP_ICH_needs: '',
                S_OP_ICH_otherReason: ''
        }   //青少年问诊主诉数据
        state.teenagerYbzz = {
            S_OP_ICH_SeQ1: '',
                S_OP_ICH_SeH1: '',
                S_OP_ICH_SeQ2: '',
                S_OP_ICH_SeH2: '',
                S_OP_ICH_SeQ3: '',
                S_OP_ICH_SeH3: '',
                S_OP_ICH_SeQ4: '',
                S_OP_ICH_SeH4: '',
                S_OP_ICH_SeQ5: '',
                S_OP_ICH_SeH5: '',
                S_OP_ICH_SeQ6: '',
                S_OP_ICH_SeH6: '',
                S_OP_ICH_symptomArray: '0,0,0,0,0,0',
                S_OP_ICH_remark2: ''
        }  //青少年眼部症状
        state.teenagerYyxg = {
            S_OP_ICH_readingTime: '',
                S_OP_ICH_electronicTime: '',
                S_OP_ICH_sleepTime: '',
                S_OP_ICH_outdoorTime: '',
                S_OP_ICH_remark3: '',
                S_OP_ICH_eyesEnvirArray: '0,0,0,0',
                S_OP_ICH_homeworkArray: '0,0,0',
                S_OP_ICH_writtingPostArray: '0,0,0'
        }  //青少年习惯用眼
        state.teenagerJkms = {
            S_OP_ICH_FPDes1: '',
                S_OP_ICH_sicknessDuration1: '',
                S_OP_ICH_FPDes2: '',
                S_OP_ICH_sicknessDuration2: '',
                S_OP_ICH_FPDes3: '',
                S_OP_ICH_sicknessDuration3: '',
                S_OP_ICH_FPDes4: '',
                S_OP_ICH_sicknessDuration4: '',
                S_OP_ICH_FPDes5: '',
                S_OP_ICH_sicknessDuration5: '',
                S_OP_ICH_FPDes6: '',
                S_OP_ICH_sicknessDuration6: '',
                S_OP_ICH_FPDes7: '',
                S_OP_ICH_FPDes8: '',
                S_OP_ICH_HistoryArray: '0,0,0,0,0,0',
                S_OP_ICH_FPArray1: '0,0',
                S_OP_ICH_FPArray2: '0,0',
                S_OP_ICH_FPArray3: '0,0',
                S_OP_ICH_FPArray4: '0,0',
                S_OP_ICH_FPArray5: '0,0',
                S_OP_ICH_FPArray6: '0,0',
                S_OP_ICH_FPArray7: '0,0',
                S_OP_ICH_FPArray8: '0,0',
                S_OP_ICH_FPremark: '0,0'
        } //青少年身体健康描述

        state.aldultZS = {}
        state.aldultJKMS = {}
        state.aldultZYYY = {}
        state.aldultYYCJ = {}
    },

    upTeenagerData(state, data) {
        switch (Number(data.type)) {
            case 1:
                state.teenagerZs = JSON.parse(JSON.stringify(data.obj))
                break;
            case 2:
                state.teenagerYbzz =  JSON.parse(JSON.stringify(data.obj))
                break;
            case 3:
                state.teenagerYyxg = JSON.parse(JSON.stringify(data.obj))
                break;
            case 4:
                state.teenagerJkms =  JSON.parse(JSON.stringify(data.obj))
                break;
        }
    },


    UpAldult(state, data) {
        switch (Number(data.type)) {
            case 1:
                state.aldultZS = JSON.parse(JSON.stringify(data.obj))
                break;
            case 2:
                state.aldultJKMS =  JSON.parse(JSON.stringify(data.obj))
                break;
            case 3:
                state.aldultZYYY = JSON.parse(JSON.stringify(data.obj))
                break;
            case 4:
                state.aldultYYCJ =  JSON.parse(JSON.stringify(data.obj))
                break;
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
