<template>
  <div class="landing">
    <div class="ts">
      <img src="../assets/ts.png" alt="">
      <div @click.stop="gocz">操作指导书</div>
    </div>
    <img class="logo" :src="logo" alt="">
    <div class="sr">
      <input type="text" v-model="name" placeholder="用户名">
      <input type="text" v-model="password" placeholder="密码">
      <div class="wjma" @click="pass()">忘记密码？</div>
      <el-button class="dl" @click="go">登录</el-button>
    </div>
    <div class="bottom">Vision Intelligent System Union Mall</div>
    <div class="mg" v-if="mg" @click="close()">
      <div class="mg-nr">
        <img src="../assets/img/gzh.jpeg" alt="">
        <div>
          <p class="sm">Vision Intelligent System Union Mall</p>
          <p>400-8080173</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '@/publicFn/baseFn'
export default {
  name: "landing",
  data() {
    return {
      name: '',
      mg: false,
      password: '',
      logo: ''
    }
  },
  created() {

    if (this.$store.state.bases.shop && this.$store.state.bases.shop.ShopLogoURL) {
      this.logo = this.$store.state.bases.shop.ShopLogoURL
    }else {
      if (fn.GetCookie('GsData')) {
        let url = "http://route.visumall.com/webrouteget.aspx?gettype=CompanyLogo&params="
        this.logo = `${url.toString()}${fn.GetCookie('GsData').toString()}`
      }
    }

    if (fn.GetCookie('vps')) {

      this.name = JSON.parse(fn.GetCookie('vps')).name
      this.password = JSON.parse(fn.GetCookie('vps')).pass
    }
  },
  methods: {
    pass() {
      this.mg = true
    },
    close() {
      this.mg = !this.mg
    },
    go() {
      if (!this.name || !this.password) {
         this.$msgbox('请输入账号或密码','提示')
         return
      }



      const log = this.$loading({
        text: '登录中...'
      })
      this.$store.commit("bases/UpShop", "")
      this.$store.commit('users/UpUser', '')
      this.$store.commit('users/upEmployees', '')
      this._api.userCurdApi.userLing(this.name, this.password)
      .then(res => {
        log.close()
        if (res.Result) {


          fn.SetCookie('GsData', res.Result.Company.CompanyLogo)

          this.$store.commit("bases/UpShop", "")
          let objs = JSON.parse(JSON.stringify(res.Result))
          // console.log(objs)
          let items = {}
          let wcitems = {}
          // console.log(objs)
          this.$store.commit('users/UpUser', objs)
          if (objs.UserCompanyType === 'shop') {
            let arr = Array.from(objs.ListUserFunction)
            let wcarr = objs.UserPosition.split(',')
            for (let i = 0; i < arr.length; i++)
            {
              items[arr[i]] = arr[i]
            }
            for (let j = 0; j < wcarr.length; j++)
            {
              wcitems[wcarr[j]] = wcarr[j]
            }
            this.$store.commit('users/upJurisdiction', items)
            this.$store.commit('users/upWcJurisdiction', wcitems)

            let userxx = {
              name: this.name,
              pass: this.password
            }
            if (fn.GetCookie('vps')) {
              let cookieObj = JSON.parse(fn.GetCookie('vps'))
              if (cookieObj.name !== this.name || cookieObj.pass !== this.password) {
                fn.SetCookie('vps', JSON.stringify(userxx))
                this.$store.commit('isShow/upishmd', true)
              }else {
                this.$store.commit('isShow/upishmd', false)
              }
            }else {
              fn.SetCookie('vps', JSON.stringify(userxx))
            }

            return this.$msgbox({
              message: res.msg,
              title: '提示',
              callback: (r) => {
                this.$router.push({path:'/'})
              }
            })

          }else if (objs.UserCompanyType === 'company') {
            this.$store.commit('users/upusererp', {
              name: this.name,
              pwd: this.password
            })
            let configArr = objs.Company.ListCompanyConfig || []
            let src = ""
            if (configArr.length) {
              let isy = configArr.some(item => {
                return item.BCKey === "ERPSource"
              })
              if (isy) {
                src = `/employeesLanding?type=1&name=${this.name}&pass=${this.password}`
              }else {
                src = `/employeesLanding?type=2&name=${this.name}&pass=${this.password}`
              }
              this.$router.push({path: src})
            }else {
              src = `/employeesLanding?type=1&name=${this.name}&pass=${this.password}`
              this.$router.push({path: src})
            }
          }else {
            return this.$msgbox({
              message: '登录失败，您的账号有误！',
              title: '提示'
            })
          }
        }else {
          this.$msgbox({
            message: res.msg,
            title: '提示'
          })
        }
      })
      .catch(err => {
        // console.log(err)
      })
    },

    gocz() {
      window.open('http://www.visumall.com/Product/Index.html')
      //this.$router.push({path: '/Iframe', query: {type: 15, src: 'http://www.visumall.com/Product/Index.html'}})
    }
  }
}
</script>

<style scoped lang="scss">
  .landing {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .ts {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: .4rem 0 0 .2rem;
    img {
      display: block;
      width: .35rem;
      height: .35rem;
      margin-right: 10px;
    }
  }
  .logo {
    display: block;
    //width: 40%;
    height: 20vh;
    margin: 10vh auto;
  }
  .sr {
    width: 100vw;
    input {
      display: block;
      outline: none;
      border: none;
      border-bottom: 1px solid #000000;
      width: 3rem;
      margin: .30rem auto;
      color: #8f8e8e;
      padding: 10px 0;
    }
    .wjma {
      width: 3rem;
      margin: .3rem auto;
      text-align: right;
      cursor: pointer;
    }
    .dl {
      width: 3rem;
    }
  }
  .bottom {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
  }
  .mg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 2rem;
      height: 2rem;
    }
    .mg-nr {
      background: #ffff;
      display: flex;
      justify-content: start;
      align-items: center;
      border-radius: 10px;
      padding: 15px;
      .sm {font-weight: bold}
    }
  }
</style>
