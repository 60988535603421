export default [
    {
        path: '/eyeDiseaseHome',
        component: () => import('@/views/eyeDisease/eyeDiseaseHome'),
        children: [
            {
                path: '/',
                component: () => import('@/views/eyeDisease/eyeDiseasePage')
            },
            {
                path: '/eyeDiseaseHome/dryEyeTreatment',
                name: 'dryEyeTreatment',
                component: () => import('../views/specialSurvey/dryEyeTreatment')
            },
        ]
    }
]
