import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// import Lselect from '@/components/CommonComponents/L-select'
// import LMultiseriateSelect from "@/components/CommonComponents/L-multiseriate-select"
import api from '@/api/index'
import pf from '@/publicFn/baseFn'
import Print from '@/plugins/print'
import axios from "@/api/axios"
import 'vant/lib/index.css';
// import {Picker} from "vant";
import popularScience from './components/CommonComponents/popularScience'
import KEYHome from './components/keyboard/keyHome'

Vue.prototype._api = api
Vue.prototype._clJson = pf.decoding
Vue.prototype._jmjson = pf.conversionJson
Vue.prototype._jl = pf.throttle
Vue.prototype._getUrl = pf.getUrl
Vue.config.productionTip = false
Vue.prototype._http = axios

Vue.component('PopularScience', popularScience);
Vue.component('KEYHome', KEYHome);


Vue.use(Print)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
