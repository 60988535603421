export default [
    {
        path: '/AfterSaleManagementHome',
        name: 'AfterSaleManagementHome',
        component: () => import('@/views/commodityJF/AfterSaleManagementHome')
    }, //售后管理主页
    {
        path: '/AfterSaleManagementHome/commodityJF',
        name: 'commodityJF',
        component: () => import('@/views/commodityJF/commodityEnd'),
    }, //商品交付
    {
        path: '/AfterSaleManagementHome/commodityHl',
        name: 'commodityHl',
        component: () => import('../views/commodityJF/commodityHl'),
    },//商品护理
    {
        path: '/reservationRecord/againReserve',
        name: 'appointmentInformation',
        component: () => import('@/components/CommonComponents/appointmentInformation')
    },//修改重新
]
