import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import users from './moddules/user'
import isShow from "@/store/moddules/isShow";
import bases from '@/store/moddules/base'
import physicianVisits from "@/store/moddules/physicianVisits";
import eyeTest from "@/store/moddules/eyeTest";
import expertClinic from "@/store/moddules/expertClinic";
import corneaContact from "@/store/moddules/corneaContact";
import training from "./moddules/training";
import Time from "./moddules/time"
import isbc from "./moddules/isbc";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    isShow,
    bases,
    physicianVisits,
    eyeTest,
    expertClinic,
    corneaContact,
    training,
    Time,
    isbc
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage//或者window.locationStorage
  })]
})
