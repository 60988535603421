<template>
  <div class="top" ref="tops">
    <div class="content borders">
      <div class="lefts">
        <i class="el-icon-arrow-left" style="font-size: .4rem;font-weight: bold;" @click="$router.back(-1)"></i>
        <img class="logo" :src="getlogo" alt="">
        <div v-if="cz">操作者：{{cz}}</div>
      </div>
      <div class="dm">{{$store.state.bases.shop.ShopName}}</div>
      <div class="top-last">
        <div style="padding-right: .2rem" @click="showcode">{{bh}}</div>
<!--        <img class="myimg" src="../assets/img/my.png" alt="">-->
        <div class="myname"><i class="el-icon-s-custom"></i>{{names}}：{{csId}}</div>
        <div style="padding-left: 10px;" @click="tebyh"><i class="el-icon-sort"></i>切换顾客</div>
        <el-dropdown trigger="click" @command="handleCommand">
          <span style="display: flex;align-items: center;font-weight: bold;font-size: 20px;margin-left: 20px;margin-top: 3px;">
            <i class="el-icon-s-fold xxs"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="text-align: center;">
            <el-dropdown-item command="a">充值记录</el-dropdown-item>
            <el-dropdown-item command="b">消费记录</el-dropdown-item>
            <el-dropdown-item command="c">修改病历时间</el-dropdown-item>
            <el-dropdown-item v-if="showinp" command="d">发送到his/pos</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img class="tuichu" src="../assets/img/tuichu.png" @click="exit" alt="">
      </div>
    </div>

    <ul class="fn">
      <li v-if="shows" @click="$router.push({path:'/csLanding'})">
        <img src="@/assets/top/yggl.png" alt="">
        <p>顾客登录</p>
      </li>
      <li  v-if="shows" @click="$router.push({path:'/employeesLanding'})">
        <img src="@/assets/top/yggl.png" alt="">
        <p>员工登录</p>
      </li>
      <li v-if="0">
        <img src="@/assets/top/sm.png" alt="">
        <p>扫码登录</p>
      </li>
      <li @click="gozy">
        <img src="@/assets/top/cz.png" alt="">
        <p>操作指引</p>
      </li>
      <li v-if="shows">
        <img src="@/assets/top/lsdd.png" alt="">
        <el-popover
            @show="showp"
            placement="bottom"
            v-model="show">
          <div class="lsbg">
            <ConsultingRecords :type="0" v-if="show" :istop="1" @sendC="gbShow"></ConsultingRecords>
          </div>
          <div slot="reference">历史报告</div>
        </el-popover>
      </li>
      <li v-if="shows">
        <img src="@/assets/top/dd.png" alt="">
        <el-popover
            @show="showp"
            placement="bottom"
            v-model="showa">
          <div class="dd">
            <SalesRecord v-if="showa" :type="1" @sendC="gbShow" :istop="1" ref="child"></SalesRecord>
          </div>
          <div slot="reference">我的订单</div>
        </el-popover>
      </li>
      <li v-if="shows" @click="gosf">
        <img src="@/assets/top/sf.png" alt="">
        <p>检查收费</p>
      </li>
      <li v-if="shows" @click="$router.push({path:'/triageList'})">
        <i style="font-size: .35rem" class="el-icon-chat-line-square"></i>
        <p>分诊列表</p>
      </li>
      <li @click="mzgl">
        <img src="@/assets/top/lc.png" alt="">
        <p>门诊管理</p>
      </li>
      <li v-if="shows" @click="csSave">
        <img src="@/assets/top/bz.png" alt="">
        <p>顾客备注</p>
      </li>
<!--      <li>-->
<!--        <img src="@/assets/top/qs.png" alt="">-->
<!--        <p>数据趋势</p>-->
<!--      </li>-->
      <li v-if="shows" @click="$router.push({path: '/PopularScienceHome'})">
        <img src="@/assets/img/kpys.png" alt="">
        <p>科普演示</p>
      </li>
    </ul>


    <el-dialog
        title=""
        :visible.sync="showczjl"
        width="1000px"
    >
      <iframe style="width: 100%;height: 400px;overflow-y: auto;" :src="czjlurl" frameborder="0"></iframe>
      <el-button type="primary" @click="showczjl = false">关闭</el-button>
    </el-dialog>

    <el-dialog
        title="请输入备注"
        :visible.sync="dialogVisible"
        width="30%"
        >
      <el-input v-model="bz" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="savebz">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        title="咨询二维码"
        :visible.sync="showewm"
        width="380px"
        >
      <canvas id="QRCode_header" ref="code"></canvas>
      <div class="yhxx">
        <div>
          <p>姓名：{{names}}</p>
          <p>性别：{{csobj.CustomerGender==1?'男':'女'}}</p>
          <p>生日：{{csobj.CustomerBirthday}}</p>
        </div>
      </div>
    </el-dialog>


    <el-dialog
        title="请选择修改时间"
        :visible.sync="showTime"
        width="400px"
    >
      <el-date-picker
          v-model="time"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期">
      </el-date-picker>
      <p>
        <el-button type="primary" @click="upTime" style="margin-top: 30px;">更新</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
import QRCode from "qrcode"
import users from "../store/moddules/user";
export default {
  name: "top",
  data() {
    return {
      iszk: 1,
      show: false,
      showa: false,
      dialogVisible: false,
      bz: '',
      showewm: false,
      showstg: false,
      showczjl: false,
      czjlurl: '',
      showTime: false,
      time: ''
    }
  },
  computed: {

    csId() {
      if (this.$store.state.users.CsUser) {
        return this.$store.state.users.CsUser.CustomerId.slice(0,8).toUpperCase()
      }else {
        return ''
      }
    },

    showinp() {
      return this.$store.state.users.user.Company.CompanyId == 'e2d78ed9-980c-49b4-9aab-f38acb9e8d9e'?true:false
    },
    csobj() {
      if (this.$store.state.users.CsUser) {
        return this.$store.state.users.CsUser
      }else {
        return {}
      }
    },

    shows() {
      if (this.$store.state.users.user.UserCompanyType !== 'shop') {
        return false
      }else {
        return true
      }
    },
    company() {
      return this.$store.state.users.user.Company
    },
    names() {
      if (this.$store.state.users.CsUser) {
        return this.$store.state.users.CsUser.CustomerName
      }else {
        return ''
      }
    },
    cz() {
      if (this.$store.state.users.employees) {
        return this.$store.state.users.employees.ExpertName
      }else {
        return false
      }
    },
    bh() {
      return this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
    },

    getlogo() {
      if (this.$store.state.bases.shop && this.$store.state.bases.shop.ShopLogoURL) {
        return this.$store.state.bases.shop.ShopLogoURL
      }else {
        if (this.$store.state.users.user) {
          if (this.$store.state.users.user.Company.CompanyLogo) {
            return this._getUrl('shopLogo') + this.$store.state.users.user.Company.CompanyLogo
          }else {
            return this._getUrl('shopLogo') + 'VisumallLogo2-2.png'
          }
        }else {
          return this._getUrl('shopLogo') + 'VisumallLogo2-2.png'
        }
      }
    }
  },
  mounted() {
    this.heights = this.$refs.tops.offsetHeight - 2 + 'px'
  },
  components: {
    ConsultingRecords: () => import('@/components/customerDetailss/consultingRecords'),
    SalesRecord: () => import('@/components/customerDetailss/salesRecord')
  },
  methods: {
    showcode() {
      this.showewm = true
      setTimeout(()=> {
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 0,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: this.$store.state.physicianVisits.xzConsulting,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };``
        // let msg = document.getElementById("QRCode_header");
        // 将获取到的数据（val）画到msg（canvas）上
        QRCode.toCanvas(this.$refs.code, this.$store.state.physicianVisits.xzConsulting, opts, function (error) {
          console.log(error)
        })
      }, 3000)``

    },

    csSave() {
      if (!this.$store.state.users.CsUser) {
        return this.$alert('顾客未登录！请登录', '提示')
            .then(res => {
              this.$router.push({path: '/csLanding'})
            })
      }
      this._api.Company.getCsRemark(this.bz)
      .then(res => {
        if (res.GetResult) {
          this.bz = res.GetResult.CCRValue
        }else {
          this.bz = ''
        }
        this.dialogVisible = !this.dialogVisible
      })
    },

    gbShow(e) {
      if (!this.$store.state.users.CsUser) {
        return this.$alert('顾客未登录！请登录', '提示')
                .then(res => {
                  this.$router.push({path: '/csLanding'})
                })
      }
      this.show = false
      this.showa = false
    },

    gosf()  {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登陆，请先登陆','提示')
            .then(res => {
              this.$router.push({path: '/employeesLanding'})
            })
      }
      if (!this.$store.state.physicianVisits.xzConsulting || !this.$store.state.users.CsUser.CustomerId) {
        return this.$alert('请选择咨询或者新建咨询！','提示')
            .then(res => {
              this.$router.push({path: '/csLanding'})
            })
      }
      let arr = this.$store.state.users.user.Company.ListCompanyConfig
      let items  = '', url = ''
      let yg =  this.$store.state.users.employees,
          csID = this.$store.state.users.CsUser.CustomerId,
          chosID = this.$store.state.physicianVisits.xzConsulting,
          uid = this.$store.state.users.user.UserId,
          shopID = this.$store.state.bases.shop.ShopId,
          ERP_com = ''
      if (arr && arr.length > 0) {
        items = arr.filter(item => item.BCKey == 'ERP_ExamSell')
      }
      ERP_com = arr.filter(item => item.BCKey == 'ERP_com')
      if (ERP_com && ERP_com.length > 0) {
        ERP_com = ERP_com[0].BCValue
      }
      if (items && items.length > 0) {
        url = items[0].BCValue
        if (!yg.ListERPCode) {
          return this.$alert('您暂无权限！')
        }
        let yscode = yg.ListERPCode[0].ObjectCode?yg.ListERPCode[0].ObjectCode:''
        if(url) {
          url = url.replace('{ERP_com}', ERP_com)
          url =url.replace('{Expert_Code}', yscode)
          url =url.replace('{CustomerId}', csID)
          url =url.replace('{ChooseId}', chosID)
          url =url.replace('{COAddUserId}', uid)
          url =url.replace('{COExpertId}', yg.ExpertId)
          url = url.replace('{COShopId}', shopID)
        }
        window.open(url)
      }else {
        this.$router.push({path: '/frameWithHome/frameChoose?type=exam'})
      }
    },

    exit() {
      if (this.$store.state.users.logged) {
        this.$alert('是否要注销客户登陆？', '提示')
        .then(res => {
            this.$store.commit('users/upLogged', false)
            pf.clCsData()
            this.$router.push({path: '/csLanding'})
        })
      }
    },

    showp() {
      if (!this.$store.state.users.CsUser) {
        this.show = false
        this.showa = false
        return this.$alert('顾客未登录！请登录', '提示')
            .then(res => {
              this.$router.push({path: '/csLanding'})
            })
      }
      this.$refs.child.getData()
    },

    savebz() {
      this._api.Company.csRemark(this.bz)
      .then(res => {
        if (!res.UpdateResult) {
          this.$alert('保存失败！', '提示')
        }
        this.dialogVisible = false
      })
    },
    gozy() {
      this.$router.push({path: '/Iframe', query: {type: 15, src: 'http://www.visumall.com/Product/Index.html'}})
    },

    mzgl() {
      this.$store.commit('bases/upBottomIdx', 10)
      let ex = this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:''
      let url = pf.getUrl('userVisumall') + `?ExpertId=${ex}`
      if (this.$store.state.users.user) {
        url = url + '&UserName=' + this.$store.state.users.user.UserName + '&Password=' +  this.$store.state.users.user.UserPWD
      }

      window.open(url)
    },

    tebyh() {
      // this.current = 1
      this.$store.commit('users/upCsUserItem', '')
      this.$store.commit('users/UpCsUser', '' )
      this.$store.commit('users/upCsAge', '')
      this.$store.commit('physicianVisits/upCancellation', true)
      this.$store.commit('physicianVisits/upXzConsulting',  '')
      this.$store.commit('isbc/upall', '')
      pf.clCsData()
      this.$emit('isLing', true)

      this.$router.push({path: '/myCustomers'})
    },

    handleCommand(e) {
      let url = ''
      this.czjlurl = ''
      switch (e) {
        case 'a':
          url = this._getUrl('Shop_InvestList')
          url = url.replace('{ShopId}', this.$store.state.bases.shop.ShopId)
          this.czjlurl = url
          this.showczjl = true
          break
        case 'b':
          url = this._getUrl('Shop_Consume_ShopAIIO')
          url = url.replace('{ShopId}', this.$store.state.bases.shop.ShopId)
          this.czjlurl = url
          this.showczjl = true
          break
        case 'c':
          if (!this.time) {
            this.$store.state.users.csObj?this.time=this.$store.state.users.csObj.ChooseAddTime.split(' ')[0]:''
            if (this.time) {
              this.time = this.time.replace(/\//g, '-')
            }
          }
          this.showTime = true
              break
        case "d":
          if (!this.$store.state.physicianVisits.xzConsulting) return this.$alert('请选择咨询', "提示")
          if (!this.$store.state.users.employees) return this.$alert('请选择医生', "提示")
          let _this = this
          window.localRequestResult = function (str) {
            console.log(str)
            try {
              let obj = JSON.parse(str)
              _this.$alert(obj.message, '提示')
            }catch (e) {
              _this.$alert(str, '提示')
              console.log(e)
            }

          }
          let obj = {
            "DevCode": "sdzy",//固定字符串
            "CompanyCode": "yzzyy",//固定字符串
            "CompanyId": "e2d78ed9-980c-49b4-9aab-f38acb9e8d9e",//固定字符串
            "ChooseId": this.$store.state.physicianVisits.xzConsulting,//中视觉的接诊id
            "COShopId": this.$store.state.bases.shop.ShopId,//固定空字符串
            "CustomerId": this.$store.state.users.CsUser.CustomerId,//中视觉的顾客id
            "S_OP_Id": "",//固定空字符串
            "COExpertId": this.$store.state.users.employees.ExpertId,//中视觉的expertid
            "COAddUserId": "2584bd90-3722-4161-a35b-7f214403475a"//固定字符串
          }
          console.log(JSON.stringify(obj))
          // console.log('http://192.168.1.36:55871/api/Visumall/AcceptPresBasicInfo')
          console.log('http://218.65.220.230:8081/api/Visumall/AcceptPresBasicInfo')
          if (window.bound) {
            // window.bound.localRequest('http://192.168.1.36:55871/api/Visumall/AcceptPresBasicInfo', JSON.stringify(obj))
            window.bound.localRequest('http://218.65.220.230:8081/api/Visumall/AcceptPresBasicInfo', JSON.stringify(obj))
          }
      }
    },

    upTime() {
      this._api.userCurdApi.Choose_UpdateChooseInfo(this.time)
      .then(res => {
        this.$alert(res.displaymsg)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .top {
    width: 100vw;
    position: relative;
    height: 13vh;
    overflow: hidden;
  }
  .borders {border-bottom: 1px solid #000000}
  .content {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .1rem 0;
    img {display: block}
    @media (min-width: 1000px) and (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .zkimg {
    display: block;
    width: .25rem;
    height: 25rem;
    transform: rotate(180deg);
  }
  .zkimgbox {
    background: #ffffff;
    border: none;
    padding: 0;
  }
  .logo {
    width: 1rem;
    height: auto;
    margin-left: .20rem;
  }
  .dm {
    font-size: .18rem;
    @media (min-width: 800px) and (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .zkbox {
    position: relative;
    padding-left: 15vw;
    padding-right: 15vw;
    .zkxx {
      background: #f1efef;
      border-radius: 10px;
      padding: .05rem;
    }
  }
  .myimg {
    width: .3rem;
    height: .3rem;
    margin-right: 1vw;
    @media (min-width: 800px) and (max-width: 1200px) {
      width: 20px;
      height: 20px;
    }
  }
  .tuichu {
    width: .3rem;
    height: auto;
    margin-left: 3vw;
    margin-right: 15px;
    @media (min-width: 800px) and (max-width: 1200px) {
      width: 20px;
      height: 20px;
    }
  }
  .fn {
    width: 100vw;
    background: #E5E5E5;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1050px) and (max-width: 1200px) {
      font-size: 13px;
    }
    @media (min-width: 500px) and (max-width: 1050px) {
      font-size: 12px;
    }
    li {
      background: #FFFFFF;
      box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.27);
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: .05rem .1rem;
      margin-right: 2vw;
      cursor: pointer;
      @media (max-width: 1200px) {
        padding: .1rem;
      }
      @media (min-width: 600px) and (max-width: 950px) {
        margin-right: 1vw;
      }
      img {
        width: .31rem;
        height: .33rem;
        margin-right: 5px;
        @media (min-width: 950px) and (max-width: 1050px) {
          margin-right: 3px;
        }
      }
    }
    li:nth-child(10) {margin: 0;}
  }
  .top-last {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .lefts {
    display: flex;
    justify-content: start;
    align-items: center;
    div {padding-left: 0.6rem}
    img {
      height: 28px;
      width: auto;
    }
  }
  .lsbg {
    width: 500px;
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .dd {
    width: 600px;
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .noqx {
    cursor: not-allowed !important;
  }

  .yhxx {
    text-align: left;
    color: #0a0a0a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    font-size: 16px;
    p{padding-bottom: 5px;}
  }
</style>
