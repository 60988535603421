import Vue from 'vue'
import VueRouter from 'vue-router'
import stores from "../store/index";
import el from "element-ui/src/locale/lang/el";
import corneaContactR from "@/router/corneaContactR";
import frameWith from "@/router/frameWith";
import training from "@/router/training"
import ys from "@/router/ys"
import shgl from "@/router/AfterSaleManagement"
import ykb from "@/router/eyeDisease"

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import('@/views/checkHomePage')
  }, //首页

  {
    path: '/TransferingConsultation',
    name: 'TransferingConsultation',
    component: () => import('@/views/eyeTransferingConsultation/TransferingConsultation')
  },

  {
    path: '/viewfnwj',
    name: 'viewfnwj',
    component: () => import('@/views/accepts/viewfnwj')
  },

  {
    path: '/home/examinationEyesHome',
    component: () => import('@/views/specialSurvey/examinationEyesHome'),
    children: [
      {
        path: "/",
        component: () => import('@/views/eyeTest/eyeViews/examinationVisualAcuity')
      }
    ]
  }, //特检科

  {
    path: '/home/acceptsHome',
    name: 'acceptsHome',
    component: () => import('@/views/accepts/acceptsHome')
  }, //接诊问诊

  {
    path: '/userLing',
    name: 'UserLanding',
    component: () => import('@/components/landing'),
    meta: { requiresAuth: true }
  },  //用户登陆

  {
    path: '/home/examinationEyesHome/ViewFunctionTest',
    name: 'ViewFunctionTest',
    component: () => import('@/views/specialSurvey/ViewFunctionTest')
  },//视功能检查

  {
    path: '/home/expertClinicHome',
    name: 'expertClinicHome',
    component: () => import('@/views/expertClinic/expertClinicHome')
  }, //专家诊室

  {
    path: '/employeesLanding',
    name: 'employeesLanding',
    component: () => import('@/views/employeesLanding')
  },//跳转到员工登陆

  {
    path: '/csLanding',
    name: 'customersLog',
    component: () => import('@/views/accepts/customer/csLandingHome')
  },  //顾客登陆

  {
    path: '/Iframe',
    name: 'Iframe',
    component: () => import('@/components/CommonComponents/Iframe')
  },//跳转到系统以外的页面
  {
    path: '/ifame2',
    name: 'ifame2',
    component: () => import('@/components/CommonComponents/ifame2')
  },//跳转到系统以外的页面2
  {
    path: '/ifame3',
    name: 'ifame3',
    component: () => import('@/components/CommonComponents/iframe3')
  },//跳转到系统以外的页面3

  ...corneaContactR,//角膜接触镜的路由

  {
    path: '/settlementCenter',
    name: 'kaidanHome',
    component: () => import('@/views/kaidan/kaidanHome')
  }, //结算中心


  {
    path: '/CRMcontent',
    name: 'CRMcontent',
    component: () => import('@/views/CRMcontent/CRMcontent')
  },  //CRM中心

  ...frameWith,  //框架定配中心

  {
    path: '/myCustomers',
    name: 'myCustomers',
    component: () => import('@/views/myCustomers')
  }, //我的顾客

  {
    path: '/HealthCheck',
    name: 'HealthCheck',
    component: () => import('@/components/customerDetailss/HealthCheck')
  }, //顾客筛查

  {
    name: 'triageList',
    path: '/triageList',
    component: () => import('@/components/customerDetailss/triageList')
  },  //分诊中心

  {
    path: '/reservationRecord',
    name: 'reservationRecord',
    component: () => import('@/views/reservationRecord')
  },//跳转预约记录


  ...training,  //视觉训练

  ...ys,

  ...shgl, //商品交付

  ...ykb,

  {
    path: '/RefractiveDepartmentHome',
    name: 'RefractiveDepartmentHome',
    component: () => import('@/views/refractiveDepartment/RefractiveDepartmentHome')
  },
  {
    path: '/optometryHome',
    name: 'optometryHome',
    component: () => import('@/views/optometry/optometryHome')
  },
  {
    path: '/reserveHome',
    name: 'reserveHome',
    component: () => import('@/views/reserveList/reserveHome')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // 判断显示底部菜单和顶部菜单的控制

  if (to.name == 'UserLanding') {
    stores.commit('isShow/upTops', false)
    stores.commit('isShow/upBottom', false)
  }else {
    stores.commit('isShow/upTops',   true)
    stores.commit('isShow/upBottom', true)
  }



  //判断是否有登陆
  if (to.name == 'UserLanding') {
    next()
  }else {
    if (!stores.state.users.user) {
      next('/userLing')
    }else {
      next()
    }
  }


  if (stores.state.users.user && stores.state.users.user.UserCompanyType === 'company') {
    // UserCompanyType === 'company'
    if (to.name !== 'employeesLanding' && to.name !== 'Iframe') {
      next('/employeesLanding?type=2&'+'name='+stores.state.users.usererp.name + '&pass='+stores.state.users.usererp.pwd)
    }
  }


  if (stores.state.users.user && stores.state.users.user.UserCompanyType !== 'company') {
    let obj = {
      UserLanding: 1,
      csLanding: 1,
      customersLog: 1,
      myCustomers: 1,
      reservationRecord: 1,
      AfterSaleManagementHome: 1,
      commodityJF: 1,
      commodityHl: 1
    }
    if (!obj[to.name] && to.path !== '/' && to.path!=='/corneaContact/Iframe' && to.path!=="/Iframe" && to.path != '/employeesLanding' && to.name !== 'appointmentInformation' && to.name !== 'triageList') {
      if (!stores.state.physicianVisits.xzConsulting) {
        next('/csLanding')
      }else {
        next()
      }
    }else {
      next()
    }
  }else {
    next()
  }



  // 判断是否进去角膜触摸镜片
  if (to.path.indexOf('acceptsHome') == -1 && to.path.indexOf('examinationEyesHome') == -1 && to.path.indexOf('expertClinicHome') == -1 && to.path.indexOf('settlementCenter') == -1 && to.path.indexOf('CRMcontent') == -1 && to.path.indexOf('AfterSaleManagementHome') == -1) {
    stores.commit('isShow/upBottom', false)
    if (to.path == '/') {
      stores.commit('isShow/upBottom', true)
    }
  }else {
    stores.commit('isShow/upBottom', true)
  }

  if (to.path == '/userLing') {
    stores.commit('isShow/upBottom', false)
  }

  // // 判断是否进去角膜触摸镜片
  // if (to.path.indexOf('RefractiveDepartmentHome') !== -1) {
  //   stores.commit('isShow/upBottom', false)
  // }
  //
  // // 判断是否进去框架定配
  // if (to.path.indexOf('frameWithHome') !== -1 || to.path.indexOf('training') !== -1) {
  //   stores.commit('isShow/upBottom', false)
  // }
})

export default router
