<template>
  <div class="keyHome">
    <ul class="left">
      <li v-for="item in leftarr" @click.stop="leftdj(item)" >{{item}}</li>
    </ul>
    <div style="display: flex;justify-content: start;align-items: center;padding-top: 5px;padding-right: 10px;">
      <ul class="r1">
        <li @click.stop="ad">-/+</li>
        <li style="position: relative;" @click.stop="showts=!showts">
          <p>^n</p>
          <i v-if="showts" class="el-icon-caret-right is"></i>
          <p class="ts" v-if="showts">
            <span @click.stop="tefh('^-3')">^-3</span>
            <span @click.stop="tefh('^-2')">^-2</span>
            <span @click.stop="tefh('^-1')">^-1</span>
            <span @click.stop="tefh('^1')">^1</span>
            <span @click.stop="tefh('^2')">^2</span>
            <span @click.stop="tefh('^3')">^3</span>
          </p>
        </li>
<!--        <li>R=L</li>-->
      </ul>
      <ul class="r2">
        <li @click.stop="dhm('.75')">.75</li>
        <li @click.stop="dhm('.50')">.50</li>
        <li @click.stop="dhm('.25')">.25</li>
        <li @click.stop="dhm('.')">.</li>
      </ul>
      <div class="r3">
        <div>
          <ul>
            <li @click.stop="pusz('7')">7</li>
            <li @click.stop="pusz('4')">4</li>
            <li @click.stop="pusz('1')">1</li>
          </ul>
          <ul>
            <li @click.stop="pusz('8')">8</li>
            <li @click.stop="pusz('5')">5</li>
            <li @click.stop="pusz('2')">2</li>
          </ul>
          <ul>
            <li @click.stop="pusz('9')">9</li>
            <li @click.stop="pusz('6')">6</li>
            <li @click.stop="pusz('3')">3</li>
          </ul>
        </div>
        <p @click.stop="pusz('0')">0</p>
      </div>
      <ul class="r4">
        <li @click.stop="dele">X</li>
        <li>Return</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "keyHome",
  props: {
    keyval: {

    }
  },
  data() {
    return {
      leftarr: [],
      showts: false
    }
  },
  created() {
    let leftarr = [], i = 2.5

    while (true) {
      let str = i.toString(),zf = "+"
      Number(i) >= 0?zf='+':zf=""
      if (Number(i) >= 0) {
        if (str.length == 3) {
          str = str+'0'
        }else if (str.length == 1) {
          str = str+'.00'
        }
      }else {
        if (str.length == 4) {
          str = str+'0'
        }else if (str.length == 2) {
          str = str+'.00'
        }
      }
      str = zf + str
      leftarr.push(str)
      if (i <= -4.5) {
        break
      }
      i = i - 0.25
    }
    leftarr.push("-5.00")
    this.leftarr = leftarr
  },
  methods: {
    leftdj(str) {
      this.$emit('changeval', str.toString())
    },
    dele() {
      if (this.keyval !== 0 && this.keyval !== '') {
        let str = this.keyval.toString()
        str = str.substr(0, str.length-1)
        this.$emit('changeval', str.toString())
      }
    },
    ad() {
      let str = this.keyval.toString()
      if(this.keyval !== 0 && this.keyval !== '') {
        if (str[0] == '+') {
          str = str.replace('+', '-')
        }else if (str[0] == '-') {
          str = str.replace('-', '+')
        }else {
          str = '-' + str
        }
      }else {
        str = '-'
      }
      this.$emit('changeval', str.toString())
    },
    pusz(str) {
      let strs = ''
      if (this.keyval !== 0 && this.keyval !== '' && this.keyval) {
        strs = this.keyval.toString()
      }
      strs += str
      this.$emit('changeval', strs.toString())
    },
    dhm(str) {
      if (this.keyval !== 0 && this.keyval === '') {
        return false
      }
      if (this.keyval.length == 1 && (this.keyval == '-' || this.keyval == '+')) {
        return false
      }
      let strs = this.keyval.toString()
      if (strs.indexOf('.') > -1 && str !== '.') {
        let arr = strs.split('.')
        strs = arr[0] + str
      }else if (strs.indexOf('.') > -1){
        return false
      }else {
        strs = strs + str
      }
      this.$emit('changeval', strs.toString())
    },
    tefh(str) {
      this.showts = false
      if (this.keyval !== 0 && this.keyval === '') {
        return false
      }
      if (this.keyval.length == 1 && (this.keyval == '-' || this.keyval == '+')) {
        return false
      }
      let strs = this.keyval.toString()
      if (strs.indexOf('^') > -1) {
        let arr = strs.split('^')
        strs = arr[0] + str
      }else {
        strs = strs + str
      }
      this.$emit('changeval', strs.toString())
    }
  }
}
</script>

<style scoped lang="scss">
  .keyHome {
    width: 850px;
    background: #e8e8e8;
    height: 280px;
    position: fixed;
    bottom: 10px;
    z-index: 9999;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 11px 16px 0px rgba(11,3,6,0.2);
    cursor: pointer;
  }
  ul {
    li {
      background: #FFFFFF;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li:active {
      background: #b8b8b8;
    }
  }
  .left {
    display: flex;
    width: 280px;
    flex-wrap: wrap;
    padding-left: 10px;
    li {
      width: 50px;
      padding: 10px 0;
      background: #FFFFFF;
      margin-left: 5px;
      margin-top: 5px;
      border-radius: 3px;
    }
    li:active {
      background: #b8b8b8;
    }
  }

  .r1 {
    li {
      width: 60px;
      height: 55px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
    li:nth-child(3){
      height: 120px;
      margin-bottom: 0;
    }
  }
  .r2 {
    padding-left: 10px;
    li {
      width: 60px;
      height: 55px;
      margin-bottom: 10px;
    }
    li:nth-child(4) {margin-bottom: 0;font-size: 30px;}
  }
  .r3 {
    padding-left: 10px;
    div {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    ul {
      padding-left: 10px;
      li {
        width: 60px;
        height: 55px;
        margin-bottom: 10px;
      }
    }
    ul:nth-child(1) {padding-left: 0}
    p {
      width: 200px;
      height: 55px;
      background: #FFFFFF;
      border-radius: 3px;
      line-height: 55px;
    }
    p:active {
      background: #b8b8b8;
    }
  }
  .r4 {
    padding-left: 10px;
    li {width: 100px}
    li:nth-child(1) {height: 55px;}
    li:nth-child(2) {
      margin-top: 10px;
      height: 185px;
    }
  }

  .ts {
    font-size: 13px;
    position: absolute;
    left: -65px;
    top: -60px;
    border-radius: 5px;
    span {
      display: block;
      background: #FFFFFF;
      padding: 8px 20px;
    }
    span:active {
      background: #b8b8b8;
    }
  }
  .is {
    color: #FFFFFF;
    position: absolute;
    left: -15px;
  }
</style>
