import da from "element-ui/src/locale/lang/da";
import fa from "element-ui/src/locale/lang/fa";

let state = {
    expertClinicData: '',  //专家诊室的所有数据
    viewPlan: [],
    isupData: 222,
    qmUrl: '',
    isSave: ''
}

let getters = {
    getisbh: state =>  state.isupData
}

let actions = {

}

let mutations = {
    upExpertClinicData(state, data) {
        state.expertClinicData = data
    },
    upViewPlan(state, data) {
        state.viewPlan = data
    },
    upisupData(state, data) {
        state.isupData = data
    },
    upQmUrl(state, data) {
        state.qmUrl = data
    },
    upAllData(state, data) {
        state.expertClinicData= ''  //专家诊室的所有数据
        state.viewPlan=[]
        state.isupData= 222
        state.qmUrl = ''
    },
    upIsSave(state, data) {
        state.isSave = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
